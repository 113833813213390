import { ChangeEvent, useState } from "react";
import { TiGift } from "react-icons/ti";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  createTheme,
} from "@mui/material";
import lightThemeOptions from "../../theme/lightThemeOptions";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StoreState, app } from "../../store/ducks";
import { TranslationsEnum } from "../../constants/TranslationsEnum";
import { setPhoneNumber } from "../../store/ducks/order";
import MuiPhoneNumber from "mui-phone-number";
const lightTheme = createTheme(lightThemeOptions);

interface OTPComponentProps {
  handleOpenOTP: () => void;
}
function OTPComponent({ handleOpenOTP }: OTPComponentProps) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState<boolean>(false);
  const [phoneNumber, setLocalPhoneNumber] = useState<string>("");
  const handlePhoneChange = (e: any) => {
    dispatch(setPhoneNumber(e.slice(1)));
    setLocalPhoneNumber(e.slice(1));
    if (e.length >= 9) {
      setError(false);
    }
  };
  const handleClick = () => {
    if (phoneNumber.length < 9) {
      setError(true);
    } else {
      handleOpenOTP();
    }
  };
  return (
    <Grid container direction="column">
      <Grid item sx={classes.OTP}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <TiGift size={"2.5rem"} style={classes.giftIcon} />
          <Typography variant="h6" sx={classes.otpTitle}>
            {t(TranslationsEnum.earnCashback)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            mt: "10px",
          }}
        >
          <Typography variant="body1" sx={classes.otpText}>
            {t(TranslationsEnum.cashbackText)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              mt: "10px",
            }}
          >
            <MuiPhoneNumber
              variant="outlined"
              onChange={handlePhoneChange}
              placeholder={t(TranslationsEnum.otpinputPlaceholder)}
              sx={classes.textField}
              error={error}
              defaultCountry="ae"
              disableAreaCodes={true}
            />
            <Typography
              sx={{
                color: "red",
                fontSize: "14px",
                fontWeight: "200",
                height: "16px",
              }}
            >
              {error && "Please enter a valid number"}
            </Typography>
          </Box>
          <Button variant="contained" sx={classes.button} onClick={handleClick}>
            {" "}
            {t(TranslationsEnum.sendOTP)}{" "}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

const classes = {
  OTP: {
    backgroundColor: "#60381317",
    mx: "1rem",
    my: "0.5rem",
    borderRadius: "10px",
    padding: "10px",
  },
  otpTitle: {
    color: "black",
    fontSize: "22px",
    lineHeight: "1.5",
    fontWeight: "bold",
  },
  otpText: {
    color: "black",
    fontSize: "14px",
    lineHeight: "1",
    fontWeight: "light",
    textAlign: "center",
  },
  textField: {
    "& svg": { height: "1em" },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      borderColor: lightTheme.palette.primary.main,
      "& .MuiInputBase-input": {
        padding: "10px",
        // textAlign: "center",
        fontSize: "16px",
        fontWeight: "medium",
        color: lightTheme.palette.primary.main,
      },
      "& fieldset": {
        borderRadius: "10px",
        borderWidth: "2px",
        borderColor: "#8d5d2a",
        fontSize: "14px",
      },
      "&.Mui-focused fieldset": {
        borderColor: lightTheme.palette.primary.main,
      },
      "&:hover fieldset": {
        borderColor: lightTheme.palette.primary.main,
      },
    },
  },
  giftIcon: {
    color: lightTheme.palette.primary.main,
  },
  button: {
    backgroundColor: lightTheme.palette.primary.main,
    borderRadius: "10px",
    mb: "5px",
  },
};

export default OTPComponent;
