import {
  Backdrop,
  Box,
  Button,
  Grid,
  Slide,
  SxProps,
  Typography,
  createTheme,
} from "@mui/material";

import lightThemeOptions from "../../theme/lightThemeOptions";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FcGoogle } from "react-icons/fc";
const lightTheme = createTheme(lightThemeOptions);

type Props = {
  open: boolean;
  onClose: () => void;
};
function RateAndReviewOnGoogle({ open, onClose }: Props) {
  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 0,
          alignItems: "flex-end",
        }}
        open={open}
        onClick={(e) => handleClose()}
      >
        <Slide in={open} direction="up">
          <Box sx={classes.containerBox} onClick={(e) => e.stopPropagation()}>
            <Grid container sx={classes.containerGrid}>
              <Grid
                item
                sx={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                xs={12}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <Typography sx={classes.walletTitle}>
                      Rate & Review on Google
                    </Typography>
                    <IoIosCloseCircleOutline
                      color="red"
                      onClick={handleClose}
                      size={"24px"}
                    />
                  </Box>
                  <Typography
                    sx={{
                      ...classes.walletTitle,
                      fontSize: "12px",
                      my: 0,
                      py: 0,
                    }}
                  >
                    Share your experience with others
                  </Typography>
                </Box>
                <Box sx={{ width: "100%", mt: "15px" }}>
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={<FcGoogle />}
                    sx={{ borderRadius: "15px", color: "black" }}
                    LinkComponent={"a"}
                    href="https://g.page/r/CStBjLXfGTkZEB0/review"
                    target="_blank"
                  >
                    Review This Place
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Slide>
      </Backdrop>
    </>
  );
}
const classes = {
  containerBox: {
    boxSizing: "border-box",
    position: "relative",
    width: "100%",
    background: "#FFFFFF",
    padding: {
      xs: "35px",
      sm: "1rem",
      md: "1.5rem",
    },
    borderRadius: "50px 50px 0px 0px",
  },
  containerGrid: {
    height: "100%",
    gap: "1rem",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
  },
  walletTitle: {
    fontWeight: "bold",
    fontSize: "15px",
    color: lightTheme.palette.secondary.main,
    mb: "16px",
  },
  walletSubtitle: {
    fontWeight: "medium",
    fontSize: "14px",
    color: lightTheme.palette.secondary.main,
    width: "100%",
  },
  authContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  authenticateButton: {
    backgroundColor: "black",
    width: "80%",
    textTransform: "unset",
    fontSize: "14px",
    fontWeight: "bold",
    borderRadius: "32px",
    my: "18px",
    py: "10px",
    "&:hover": {
      backgroundColor: "black",
    },
    "&:disabled": {
      backgroundColor: "gray",
      color: "#3a3a3a",
    },
  },
  useAmountButton: {
    backgroundColor: "black",
    textTransform: "unset",
    fontSize: "12px",
    fontWeight: "medium",
    borderRadius: "32px",
    py: "13px",
    width: "48%",
    "&:hover": {
      backgroundColor: "black",
    },
  },
  customAmountButton: {
    backgroundColor: "transparent",
    textTransform: "unset",
    fontSize: "12px",
    fontWeight: "medium",
    borderRadius: "32px",
    color: lightTheme.palette.secondary.main,
    py: "10px",
    width: "48%",
    boxShadow: "none",
    border: "1px solid #C4C4C4",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  textField: {
    "& svg": { height: "1em" },
    "& .MuiOutlinedInput-root": {
      "& .MuiInputBase-input": {
        padding: "10px",
        px: "19px",
        color: lightTheme.palette.secondary.main,
        fontSize: "15px",
        fontWeight: "bold",
      },
      "& fieldset": {
        borderRadius: "32px",
        borderColor: "transparent !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
    },
  },
  finalPayment: {
    backgroundColor: "black",
    textTransform: "unset",
    fontSize: "15px",
    fontWeight: "bold",
    borderRadius: "32px",
    mb: "5px",
    py: "10px",
    "&:hover": {
      backgroundColor: "black",
    },
    "&:disabled": {
      backgroundColor: "gray",
      color: "#3a3a3a",
    },
    minWidth: "250px",
  } as SxProps,
};
export default RateAndReviewOnGoogle;
