import {
  Backdrop,
  Box,
  Button,
  Divider,
  Grid,
  Slide,
  Typography,
  createTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslationsEnum } from "../../constants/TranslationsEnum";
import lightThemeOptions from "../../theme/lightThemeOptions";
import { useState } from "react";
import CustomAmountModal from "../SplitBillModals/CustomAmountModal";
import SplitEquallyModal from "../SplitBillModals/SplitEquallyModal";
import PayForItemsModal from "../SplitBillModals/PayForItemsModal";
import { MenuItem } from "../../models/API";
import { detectPaymentType } from "../../helpers/utils";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/ducks";
const lightTheme = createTheme(lightThemeOptions);

type Props = {
  open: boolean;
  onClose: () => void;
  handleSuccessfull: (value: number, selectedMenuItems?: MenuItem[]) => void;
  handleRemoveSplitBill: () => void;
};
function SplitBillModal({
  open,
  onClose,
  handleSuccessfull,
  handleRemoveSplitBill,
}: Props) {
  const { t } = useTranslation();
  const order = useSelector((state: StoreState) => state.order.order);
  const handleClose = () => {
    onClose();
  };
  const handleSplitBill = (choice: number) => {
    setChoice(choice);
  };
  const [choice, setChoice] = useState(0);
  const handleCloseSplitModals = () => {
    setChoice(0);
  };
  const succefullSubmit = (
    value: string | number,
    selectedMenuItems?: MenuItem[]
  ) => {

    handleClose();
    handleSuccessfull(+value, selectedMenuItems);
  };
  const handleRemoveSplit = () => {
    handleRemoveSplitBill();
    handleCloseSplitModals();
  };
  return (
    <>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 0,
          alignItems: "flex-end",
        }}
        onClick={(e) => handleClose()}
        open={open}
      >
        <CustomAmountModal
          open={choice === 1}
          onClose={handleCloseSplitModals}
          handleSubmit={succefullSubmit}
          handleRemove={handleRemoveSplit}
        />
        <SplitEquallyModal
          open={choice === 2}
          onClose={handleCloseSplitModals}
          handleSubmit={succefullSubmit}
          handleRemove={handleRemoveSplit}
        />
        <PayForItemsModal
          open={choice === 3}
          onClose={handleCloseSplitModals}
          handleSubmit={succefullSubmit}
          handleRemove={handleRemoveSplit}
        />
        <Slide in={open} direction="up">
          <Box sx={classes.containerBox} onClick={(e) => e.stopPropagation()}>
            <Grid container sx={classes.containerGrid}>
              <Grid
                item
                sx={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
                xs={12}
              >
                <Box>
                  <Typography sx={classes.walletTitle}>
                    {" "}
                    {t(TranslationsEnum.splitBillTitle)}
                  </Typography>
                  <Typography sx={classes.walletSubtitle}>
                    {t(TranslationsEnum.splitBillSubTitle)}
                  </Typography>
                </Box>
                <Box sx={classes.choicesContainer}>
                  <Box sx={classes.choiceBox}>
                    <Typography sx={classes.choiceText}>
                      {t(TranslationsEnum.splitBillOption1)}
                    </Typography>
                    <Button
                      onClick={() => handleSplitBill(1)}
                      variant="contained"
                      sx={classes.selectButton}
                    >
                      {t(TranslationsEnum.splitBillSelect)}
                    </Button>
                  </Box>
                  <Divider orientation={"horizontal"} />
                  <Box sx={classes.choiceBox}>
                    <Typography sx={classes.choiceText}>
                      {t(TranslationsEnum.splitBillOption2)}
                    </Typography>
                    <Button
                      onClick={() => handleSplitBill(2)}
                      variant="contained"
                      sx={classes.selectButton}
                    >
                      {t(TranslationsEnum.splitBillSelect)}
                    </Button>
                  </Box>
                  <Divider orientation={"horizontal"} />
                  <Box sx={classes.choiceBox}>
                    <Typography sx={classes.choiceText}>
                      {t(TranslationsEnum.splitBillOption3)}
                      {/* {Boolean(
                        order && detectPaymentType(order).customPayment
                      ) && (
                        <Typography color="error" component={"span"}>
                          *
                        </Typography>
                      )} */}
                    </Typography>
                    <Button
                      onClick={() => handleSplitBill(3)}
                      variant="contained"
                      sx={classes.selectButton}
                      disabled={Boolean(
                        order && detectPaymentType(order).customPayment
                      )}
                    >
                      {t(TranslationsEnum.splitBillSelect)}
                    </Button>
                  </Box>
                  {Boolean(order && detectPaymentType(order).customPayment) && (
                    <Box sx={{ mt: "-10px" }}>
                      <Typography color="error" fontSize={11}>
                        Disabled due to the detection of a custom split payment.
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Slide>
      </Backdrop>
    </>
  );
}
const classes = {
  containerBox: {
    boxSizing: "border-box",
    position: "relative",
    width: "100%",
    background: "#FFFFFF",
    padding: {
      xs: "35px",
      sm: "1rem",
      md: "1.5rem",
    },
    borderRadius: "50px 50px 0px 0px",
    maxWidth: "500px",
  },
  containerGrid: {
    height: "100%",
    gap: "1rem",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
  },
  walletTitle: {
    fontWeight: "bold",
    fontSize: "16px",
    color: lightTheme.palette.secondary.main,
  },
  walletSubtitle: {
    fontWeight: "medium",
    fontSize: "14px",
    color: lightTheme.palette.secondary.main,
  },
  walletText: {
    fontWeight: "bold",
    fontSize: "16px",
    color: lightTheme.palette.secondary.main,
  },
  selectButton: {
    backgroundColor: "#F5F5F5",
    borderRadius: "32px",
    color: lightTheme.palette.secondary.main,
    fontSize: "14px",
    boxShadow: "none",
    fontWeight: "medium",
  },
  choiceBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  choiceText: {
    color: lightTheme.palette.secondary.main,
    fontSize: "14px",
    fontWeight: "medium",
  },
  choicesContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
};
export default SplitBillModal;
