import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  Backdrop,
  Box,
  Button,
  Grid,
  Slide,
  Typography,
  createTheme,
} from "@mui/material";
import { GoPerson } from "react-icons/go";
import { FaMinus, FaPlus } from "react-icons/fa6";
import lightThemeOptions from "../../theme/lightThemeOptions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/ducks";
import { setNumberOfPeople } from "../../store/ducks/order";
import { calcWithServiceFees } from "../../pages/Invoice/InvoiceTable";
const lightTheme = createTheme(lightThemeOptions);

type Props = {
  open: boolean;
  onClose: () => void;
  handleSubmit: (value: number) => void;
  handleRemove: () => void;
};
function SplitEquallyModal({
  open,
  onClose,
  handleSubmit,
  handleRemove,
}: Props) {
  const { order } = useSelector((state: StoreState) => state.order);
  const handleClose = () => {
    onClose();
  };
  const serviceFee = useSelector(
    (state: StoreState) => state.app.conceptOptions?.serviceFee
  );
  const dispatch = useDispatch();
  // const handleValueChange = (e: any) => {
  //   console.log(Number(e.target.value));
  //   setValue(Number(e.target.value));
  // };
  // const [value, setValue] = useState(calcWithServiceFees({total:order?.Due,}).total);
  const [value, setValue] = useState(order?.Due!);
  const [total, setTotal] = useState(1);
  const handleTotal = (number: number) => {
    if (number === -1 && total > 1) {
      setTotal((prevState) => prevState - 1);
    }
    if (number === 1 && total <= 9) {
      setTotal((prevState) => prevState + 1);
    }
    if (number < 0 && payFor > total + number) {
      handlePayFor(number);
    }
  };
  const [payFor, setPayFor] = useState(1);
  const handlePayFor = (number: number) => {
    if (number === -1 && payFor > 1) {
      setPayFor((prevState) => prevState - 1);
    }
    if (number === 1 && payFor < total) {
      setPayFor((prevState) => prevState + 1);
    }
  };
  const handleRemoveSplit = () => {
    handleRemove();
    setPayFor(1);
    setTotal(1);
    onClose();
  };

  useEffect(() => {
    const personDue = order?.Due! / total;
    setValue(personDue * payFor);
  }, [total, payFor, order?.Due]);

  // const handleSubmitNumbers = () => {
  //   dispatch(setNumberOfPeople(total, payFor));
  // };
  return (
    <>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 0,
          alignItems: "flex-end",
        }}
        onClick={(e) => handleClose()}
        open={open}
      >
        <Slide in={open} direction="up">
          <Box sx={classes.containerBox} onClick={(e) => e.stopPropagation()}>
            <Grid container sx={classes.containerGrid}>
              <Grid
                item
                sx={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    fontSize={15}
                    fontWeight="medium"
                    color={lightTheme.palette.secondary.main}
                  >
                    Divide the Bill Equally
                  </Typography>
                  <IoIosCloseCircleOutline
                    color="red"
                    onClick={handleClose}
                    size={"24px"}
                  />{" "}
                </Box>
              </Grid>

              <Grid
                item
                sx={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  mb: "auto",
                  borderTop: "1px solid " + lightTheme.palette.primary.main,
                  px: "5px",
                  pt: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: "20px",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "5px" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border:
                          "1px solid " + lightTheme.palette.secondary.main,
                        borderRadius: "50%",
                        width: "21px",
                        height: "21px",
                      }}
                    >
                      <GoPerson color={lightTheme.palette.secondary.main} />
                    </Box>
                    <Typography
                      fontSize={15}
                      fontWeight={"medium"}
                      color={lightTheme.palette.secondary.main}
                    >
                      Total People in Your Table
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                  >
                    <Box
                      onClick={() => handleTotal(-1)}
                      sx={classes.incrementButton}
                    >
                      <FaMinus size={18} />
                    </Box>
                    <Typography
                      fontSize={14}
                      fontWeight={"medium"}
                      color={lightTheme.palette.secondary.main}
                    >
                      {total}
                    </Typography>
                    <Box
                      onClick={() => handleTotal(1)}
                      sx={classes.incrementButton}
                    >
                      <FaPlus size={18} />
                    </Box>{" "}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "5px" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border:
                          "1px solid " + lightTheme.palette.secondary.main,
                        borderRadius: "50%",
                        width: "21px",
                        height: "21px",
                      }}
                    >
                      <GoPerson color={lightTheme.palette.secondary.main} />
                    </Box>
                    <Typography
                      fontSize={15}
                      fontWeight={"medium"}
                      color={lightTheme.palette.secondary.main}
                    >
                      People You Pay for
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                  >
                    <Box
                      onClick={() => handlePayFor(-1)}
                      sx={classes.incrementButton}
                    >
                      <FaMinus size={18} />
                    </Box>
                    <Typography
                      fontSize={14}
                      fontWeight={"medium"}
                      color={lightTheme.palette.secondary.main}
                    >
                      {payFor}
                    </Typography>
                    <Box
                      onClick={() => handlePayFor(1)}
                      sx={classes.incrementButton}
                    >
                      <FaPlus size={18} />
                    </Box>{" "}
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                sx={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  mt: "auto",
                  borderTop: "1px solid " + lightTheme.palette.primary.main,
                  px: "5px",
                  pt: "10px",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    fontSize={14}
                    fontWeight={"medium"}
                    color={lightTheme.palette.secondary.main}
                  >
                    Left to Pay
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={"medium"}
                    color={lightTheme.palette.secondary.main}
                  >
                    <Typography
                      component={"span"}
                      fontSize={14}
                      fontWeight={"medium"}
                    >
                      AED&nbsp;
                    </Typography>
                    {calcWithServiceFees({
                      total: order?.Due!,
                      serviceFee: serviceFee!,
                    }).total.toFixed(2)}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    fontSize={14}
                    fontWeight={"medium"}
                    color={lightTheme.palette.secondary.main}
                  >
                    Your Share
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={"medium"}
                    color={lightTheme.palette.secondary.main}
                  >
                    <Typography
                      component={"span"}
                      fontSize={14}
                      fontWeight={"medium"}
                    >
                      AED&nbsp;
                    </Typography>{" "}
                    { calcWithServiceFees({ total: value, serviceFee:serviceFee! }).total.toFixed(2)}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    variant="contained"
                    sx={classes.splitButton}
                    onClick={handleRemoveSplit}
                  >
                    Remove Split
                  </Button>
                  <Button
                    variant="contained"
                    sx={classes.payFull}
                    onClick={() => {
                      // handleSubmitNumbers();
                      handleSubmit(value);
                    }}
                  >
                    Confirm
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Slide>
      </Backdrop>
    </>
  );
}
const classes = {
  containerBox: {
    boxSizing: "border-box",
    position: "relative",
    width: "100%",
    height: "100%",
    background: "#FFFFFF",
    padding: {
      xs: "35px",
      sm: "1rem",
      md: "1.5rem",
    },
    maxWidth: "500px",
  },
  containerGrid: {
    height: "100%",
    gap: "1rem",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "start",
    flexDirection: "column",
  },
  incrementButton: {
    padding: "0px",
    width: "21px",
    height: "21px",
    backgroundColor: "#d9d9d9",
    color: "#707070",
    borderRadius: "50%",
    textAlign: "center",
    fontSize: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  splitButton: {
    width: "45%",
    backgroundColor: "#FAFAFA",
    borderRadius: "32px",
    boxShadow: "none",
    py: "10px",
    color: "red",
    fontWeight: "medium",
    fontSize: "14px",
    textTransform: "unset",
    border: "1px solid white",
    "&:hover": {
      backgroundColor: "unset",
      border: "1px solid #C4C4C4",
      boxShadow: "none",
    },
  },
  payFull: {
    width: "45%",
    backgroundColor: "#FAFAFA",
    borderRadius: "32px",
    boxShadow: "none",
    py: "10px",
    color: lightTheme.palette.secondary.main,
    fontWeight: "medium",
    fontSize: "14px",
    border: "1px solid white",
    textTransform: "unset",
    "&:hover": {
      backgroundColor: "unset",
      border: "1px solid #C4C4C4",
      boxShadow: "none",
    },
  },
};
export default SplitEquallyModal;
