import { Grid } from "@mui/material";
import { useState } from "react";
import Lottie from "react-lottie";
import * as animationData from "../../assets/data.json";

const LottieAnimation = () => {
  const [isStopped, setIsStopped] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Grid
      item
      container
      sm={12}
      justifyContent="center"
      alignItems="center"
      flexDirection={"column"}
      sx={{
        height: "100vh",
        background: "white",
        position: "absolute",
        zIndex: 9999,
        gap: "20px",
      }}
    >
      <Lottie
        options={defaultOptions}
        height={400}
        width={400}
        isStopped={isStopped}
        isPaused={isPaused}
      />
    </Grid>
  );
};

export default LottieAnimation;
