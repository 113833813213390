import axios from "axios";
import { LamdaFnLinks } from "../constants/enums";

interface PayWithSoSocialBody {
  conceptID: string;
  paymentDetailsRequestID: string;
  orderID: string;
  tableID: string;
  tableName: string;
  userName: string;
  merchant_param2: string | undefined;
  merchant_param3: string | undefined;
  merchant_param4: string | undefined;
  merchant_param5: string | undefined;
}
const payWithSoSocial = async (body: PayWithSoSocialBody) => {
  try {
    console.log("payWithSoSocial -> body", body);
    console.log(`${LamdaFnLinks.PaymentOperations}?operation=payWithSoSocial`);

    const response = await axios.post(
      `${LamdaFnLinks.PaymentOperations}?operation=payWithSoSocial`,
      body
    );

    return {
      success: response.data.success,
      paymentID: response.data.paymentID,
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
    };
  }
};

export default payWithSoSocial;
