import React, { useEffect } from "react";

const ScrollTop: React.FC = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant" as ScrollBehavior,
    });
  }, []);

  return null;
};

export default ScrollTop;
