import axios from "axios";
import { PaymentOperationIds } from "../constants/operationIds";
import { LamdaFnLinks, PaymentChannels } from "../constants/enums";

export async function encryptPaymentTrx(concept: any, paymentTransaction: any) {
  try {
    const response = await axios.post(
      LamdaFnLinks.PaymentOperations +
        `?operation=${PaymentOperationIds.CCAvenue_TRANSACTION}`,
      {
        conceptID: concept ? concept.id : "",
        paymentTransaction,
      }
    );
    const data = response.data;
    return data;
  } catch (error) {}
}

export async function encryptApplePaymentTrx(
  concept: any,
  paymentTransaction: any,
  appleResponse: any
) {
  try {
    const response = await axios.post(
      LamdaFnLinks.PaymentOperations +
        `?operation=${PaymentOperationIds.CCAvenue_APPLE_TRANSACTION}`,
      {
        conceptID: concept ? concept.id : "",
        paymentTransaction,
        appleResponse,
      }
    );
    const data = response.data;
    return data;
  } catch (error: any) {
    return JSON.stringify(error);
  }
}

export async function handleCCAvenuePayment(
  access_code: string,
  encRequest: string,
  channel: string
) {
  try {
    let action =
      "https://secure.ccavenue.ae/transaction/transaction.do?command=initiateTransaction";
    if (channel === PaymentChannels.APPLE) {
      action =
        "https://secure.ccavenue.ae/transaction/transaction.do?command=initiatePayloadTransaction";
    }

    const formHtml =
      '<form id="nonseamless" method="post" name="redirect" action="' +
      action +
      '"/> ' +
      '<input type="hidden" id="encRequest" name="encRequest" value="' +
      encRequest +
      '"><input type="hidden" name="access_code" id="access_code" value="' +
      access_code +
      '"><script language="javascript">document.redirect.submit();</script></form>';

    console.log({
      formHtml,
    });
    // Create a container div and set its innerHTML to the formHtml
    const formContainer = document.createElement("div");
    formContainer.innerHTML = formHtml;

    // Append the container to the body (or any other part of the DOM)
    document.body.appendChild(formContainer);

    // Find the form and submit it
    const form = formContainer.querySelector("form");
    if (form) {
      form.submit();
    } else {
      console.error("Form not found in the response HTML");
    }
  } catch (error) {
    console.error("Payment initiation failed", error);
  }
}

export async function handleCCAvenuePaymentIFrame(
  merchant_id: string,
  access_code: string,
  encRequest: string
) {
  try {
    const src =
      "https://secure.ccavenue.ae/transaction/transaction.do?command=initiateTransaction&merchant_id=" +
      merchant_id +
      "&encRequest=" +
      encRequest +
      "&access_code=" +
      access_code;

    return src;

    // const iFrameHtml =
    //   "<html><head><title>Sub-merchant checkout page</title>" +
    //   '<script src="http://ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min.js"></script></head><body><center>' +
    //   '<iframe  width="482" height="500" scrolling="No" frameborder="0"  id="paymentFrame" src="' +
    //   src +
    //   '"></iframe>' +
    //   '</center><script type="text/javascript">$(document).ready(function(){$("iframe#paymentFrame").load(function() {window.addEventListener("message", function(e) {$("#paymentFrame").css("height",e.data["newHeight"]+"px"); }, false);}); });' +
    //   "</script></body></html>";

    // return iFrameHtml;
  } catch (error) {
    console.error("Payment initiation failed", error);
    return "";
  }
}
