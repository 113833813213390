import { useEffect, useState } from "react";

import { Box, Button, Grid, Typography, createTheme } from "@mui/material";
import lightThemeOptions from "../../theme/lightThemeOptions";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/ducks";
import { MoneyTypes } from "../../constants/enums";
import { TranslationsEnum } from "../../constants/TranslationsEnum";
import { setTip } from "../../store/ducks/order";
import AngelEmoji from "../../assets/common/Angel emoji.webp";
import lovedEmoji from "../../assets/common/lovedEmoji.webp";
import heartEmoji from "../../assets/common/heartEyesEmoji.webp";
import coolEmoji from "../../assets/common/coolEmoji.webp";
import { FaCheckCircle } from "react-icons/fa";
import { FaPencilAlt } from "react-icons/fa";
import CustomTipModal from "./CustomInputModal";
import { displayPrices } from "../../helpers/utils";

const lightTheme = createTheme(lightThemeOptions);
// test
export const getCurrentTip = ({
  tip,
  total,
  currentDue,
}: {
  tip: number;
  total: number;
  currentDue: number;
}) => {
  if (total === 0) return 0;

  const currentTip = tip;
  return currentTip;
};

function TipsComponent() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tips } = useSelector(
    (state: StoreState) => state.app.conceptOptions
  )!;
  const { tip, order, currentDue } = useSelector(
    (state: StoreState) => state.order
  );
  const defaultTip = tips.find((tip) => tip.default === true)?.id || null;
  const [tipOption, setTipOption] = useState<string | null>(defaultTip);
  const [tipCustomValue, setTipCustomValue] = useState<number | null>(null);
  const handleTipOptions = (tipOption: any) => {
    setTipOption((prev) => (prev === tipOption.id ? null : tipOption.id));
    setTipCustomValue(null);
  };

  const handleCustomTipChange = (value: number) => {
    setTipOption(null);
    setTipCustomValue(+value);
    setIsOpenCustomTip(false);
  };

  const [emoji, setEmoji] = useState<string>(AngelEmoji);
  const getCorrespondingEmoji = () => {
    if (tipOption === null) {
      setEmoji(coolEmoji);
    } else {
      const tip = tips.find((tip) => tip.id === tipOption);
      if (tip) {
        switch (tip.id) {
          case tips[0]?.id:
            setEmoji(lovedEmoji);
            break;
          case tips[1]?.id:
            setEmoji(AngelEmoji);
            break;
          case tips[2]?.id:
            setEmoji(heartEmoji);
            break;
          default:
            setEmoji(lovedEmoji);
            break;
        }
      } else {
        // dispatch(setTip(tip?.amount || 0));
      }
    }
  };
  const handleOpenCustomTip = () => {
    setIsOpenCustomTip(true);
  };
  const [isOpenCustomTip, setIsOpenCustomTip] = useState<boolean>(false);
  useEffect(() => {
    if (tipOption === null) {
      dispatch(setTip(tipCustomValue || 0));
    } else {
      const tip = tips.find((tip) => tip.id === tipOption);
      if (tip?.type === MoneyTypes.PERCENTAGE) {
        dispatch(setTip(displayPrices((tip.amount * currentDue!) / 100)));
      } else {
        dispatch(setTip(tip?.amount || 0));
      }
    }
    getCorrespondingEmoji();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipOption, tipCustomValue, currentDue]);

  return (
    <Grid container direction="column" sx={classes.AddTip}>
      <Grid
        item
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <CustomTipModal
          open={isOpenCustomTip}
          onClose={() => setIsOpenCustomTip(false)}
          handleSubmit={handleCustomTipChange}
          isTip={true}
        />
        <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <Typography
            fontWeight={"medium"}
            fontSize={14}
            color={lightTheme.palette.secondary.main}
          >
            {t(TranslationsEnum.tipMatters)}
          </Typography>
          {!(tipOption === null && !tipCustomValue) && (
            <Box
              component="img"
              src={tipOption === null && !tipCustomValue ? "" : emoji}
              sx={{
                objectFit: "contain",
                height: "20px",
                outline: "none",
                border: "none",
                userSelect: "none",
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography sx={classes.tipTitle}>
            {t(TranslationsEnum.tipAmount)}:{" "}
            <Typography
              component={"span"}
              fontSize={"12px"}
              fontWeight={"medium"}
            >
              AED&nbsp;
            </Typography>
            {getCurrentTip({ tip, total: order?.Due!, currentDue })}
          </Typography>
        </Box>
      </Grid>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={classes.tipOptions}>
          {tips.map((option) => (
            <Box>
              {option.id === defaultTip && (
                <Typography
                  fontFamily={"Poppins"}
                  fontWeight={"bold"}
                  fontSize={12}
                  color={"secondary"}
                  textAlign={"center"}
                >
                  Most Common
                </Typography>
              )}
              <Button
                key={option.id}
                onClick={() => handleTipOptions(option)}
                variant={"contained"}
                startIcon={tipOption === option.id && <FaCheckCircle />}
                sx={{
                  border: `1px solid ${
                    tipOption === option.id ? "#C4C4C4" : "white"
                  }`,
                  transition: "all 0.3s ease",
                  borderRadius: "32px",
                  fontSize: "13px",
                  boxShadow: "none",
                  width: "100px",
                  fontWeight: 500,
                  color:
                    tipOption === option.id
                      ? lightTheme.palette.secondary.main
                      : lightTheme.palette.secondary.main,
                  backgroundColor:
                    tipOption === option.id ? "white" : "#FAFAFA",
                  "&:hover": {
                    backgroundColor:
                      tipOption === option.id ? "white" : "#FAFAFA",
                    boxShadow: "none",
                  },
                }}
              >
                {`${option.amount} ${
                  option.type === MoneyTypes.PERCENTAGE
                    ? "%"
                    : t(TranslationsEnum.AED)
                }`}
              </Button>
            </Box>
          ))}
        </Box>
        {/* Custom Tip */}
        <Box sx={{ display: "flex", alignItems: "center", mt: "10px" }}>
          <Typography fontSize={12} color={lightTheme.palette.secondary.main}>
            {t(TranslationsEnum.customTipInvoicePage)}
          </Typography>
          <Box
            sx={{
              width: "20px",
              height: "20px",
              marginLeft: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              border: "2px solid " + lightTheme.palette.secondary.main,
              borderRadius: "50%",
            }}
            onClick={handleOpenCustomTip}
          >
            <FaPencilAlt
              color={lightTheme.palette.secondary.main}
              size={"12px"}
            />
          </Box>
          {/* <InputBase
            fullWidth
            type="number"
            size="small"
            value={tipCustomValue}
            placeholder={t("CustomTip")}
            onKeyDown={(e) => {
              const illegalChars = ["e", "E", "+", "-", ".", " "];
              if (illegalChars.includes(e.key)) {
                e.preventDefault();
              }
            }}
            onWheel={(e) => {
              //@ts-ignore
              e.target.blur();
            }}
            onChange={handleCustomTipChange}
            inputProps={{ min: 0 }} // Adding minimum value
            style={{
              textAlign: "center",
              border: "1px solid #888",
              borderRadius: 15,
              fontSize: 16,
              fontWeight: 500,
              color: "#888",
              maxWidth: "120px",
              paddingLeft: i18n.language === "Arabic" ? "1rem" : "1rem",
              paddingRight: i18n.language === "Arabic" ? "1rem" : "0rem",
            }}
          /> */}
        </Box>
      </Box>
      {/* <Typography variant="h6" sx={classes.TotalAmount}>
        {t("TotalAmount") + " : " + (total + tip).toFixed(2) + " " + t("AED")}
      </Typography> */}
    </Grid>
  );
}

const classes = {
  tipTitle: {
    fontSize: "12px",
    fontWeight: "medium",
    color: lightTheme.palette.secondary.main,
  },
  TotalAmount: {
    fontFamily: "Inter",
    fontSize: "20px",
    marginTop: "1rem",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#171A1FFF",
  },
  tipText: {
    fontFamily: "Inter",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#171A1FFF",
  },
  AddTip: {
    margin: "1rem",
    borderBottom: "1px solid #f0f0f0",
    paddingBottom: "10px",
  },
  tipOptions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "end",
    width: "100%",
    marginTop: "0.5rem",
    overflowY: "auto",
    // mb: "0.5rem",
  },
};

export default TipsComponent;
