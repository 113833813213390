export enum Colors {
  PRIMARY = "#C1C1C1",
  PRIMARY_LIGHT = "#F0F0F0",
  PRIMARY_DARK = "#40260B",
  SECONDARY = "#1e2128",
  SECONDARY_LIGHT = "#2c2f38",
  SECONDARY_DARK = "#131418",
  WHITE = "#FFFFFF",
  BLACK = "#000000",
  GREY = "#F5F5F5",
  DARK_GREY = "#E0E0E0",
  LIGHT_GREY = "#F8F8F8",
  TRANSPARENT = "transparent",
  SAMSUNG_PAY = "#0453c8",
  SAMSUNG_PAY_DARKER = "#02346b",
}
