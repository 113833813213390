import {
  Backdrop,
  Box,
  Button,
  Grid,
  Slide,
  Typography,
  createTheme,
} from "@mui/material";

import lightThemeOptions from "../../theme/lightThemeOptions";
import { useState } from "react";
import { StoreState } from "../../store/ducks";
import { useSelector } from "react-redux";
import { calcWithServiceFees } from "../../pages/Invoice/InvoiceTable";
import { getCurrentTip } from "../tipsComponent/TipsComponent";
import { FaPencilAlt } from "react-icons/fa";
import CustomTipModal from "../tipsComponent/CustomInputModal";
import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  displayPrices,
  formatWithCommas,
  getEquivalentAED,
  getEquivalentSosocial,
} from "../../helpers/utils";
const lightTheme = createTheme(lightThemeOptions);

type Props = {
  open: boolean;
  setCash: (cash: number) => void;
  onClose: () => void;
};
function RedemptionModal({ open, setCash, onClose }: Props) {
  const user = useSelector((state: StoreState) => state.app.user);
  const wallet_cash = Number(user?.wallet.amount);
  const conceptOptions = useSelector(
    (state: StoreState) => state.app.conceptOptions
  );
  const { currentDue, order, tip } = useSelector(
    (state: StoreState) => state.order
  );
  const currentDueInSosocial = getEquivalentSosocial(
    calcWithServiceFees({
      total: currentDue,
      serviceFee: conceptOptions?.serviceFee!,
    }).total,
    user?.soSocialConversion
  );
  const walletInAed = getEquivalentAED(wallet_cash, user?.soSocialConversion);
  // console.log(walletInAed);
  const maxBill = displayPrices(
    currentDueInSosocial > wallet_cash
      ? walletInAed
      : calcWithServiceFees({
          total: currentDue,
          serviceFee: conceptOptions?.serviceFee!,
        }).total
  );
  const [customInput, setCustomInput] = useState<boolean>(false);
  const handleClose = () => {
    setCustomInput(false);
    // onClose();
  };
  const handleCloseThis = () => {
    // setCustomInput(false);
    if (customInput) return;
    onClose();
  };
  const handleSetCash = (cash: number) => {
    setCustomInput(false);
    setCash(cash);
  };
  // console.log({ maxBill, currentDue });
  return (
    <>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 0,
          alignItems: "flex-end",
          backgroundColor: "unset",
        }}
        open={open}
        onClick={(e) => handleCloseThis()}
      >
        <CustomTipModal
          open={customInput}
          handleSubmit={handleSetCash}
          onClose={() => setCustomInput(false)}
          max={displayPrices(
            getEquivalentSosocial(maxBill, user?.soSocialConversion)
          )}
        />
        <Slide in={open} direction="up">
          <Box
            sx={{ ...classes.containerBox, paddingBottom: "300px" }}
            onClick={(e) => e.stopPropagation()}
          >
            <Grid container sx={classes.containerGrid}>
              <Grid
                item
                sx={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                xs={12}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <Typography
                      fontSize={15}
                      fontWeight={"bold"}
                      sx={{ width: "100%", color: "#606060" }}
                    >
                      Use Social Cash
                    </Typography>
                    <IoIosCloseCircleOutline
                      color="red"
                      onClick={handleCloseThis}
                      size={"24px"}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    border: "1px solid #C4C4C4",
                    borderRadius: "32px",
                    padding: "14px",
                  }}
                >
                  <Box sx={{ mb: "16px", mt: "12px" }}>
                    <Box sx={{ justifyContent: "space-between" }}>
                      <Typography
                        fontWeight={"bold"}
                        fontSize={14}
                        sx={{
                          color: "#959595",
                          "@media (max-width: 390px)": {
                            fontSize: "12px", // Example adjustment for small screens
                          },
                        }}
                      >
                        Account Name: {user?.first_name} {user?.last_name}
                      </Typography>{" "}
                      <Typography
                        fontSize={14}
                        fontWeight={"bold"}
                        sx={{
                          color: "#959595",
                          "@media (max-width: 390px)": {
                            fontSize: "12px",
                          },
                        }}
                      >
                        Wallet Balance :{" "}
                        <Typography fontSize={"inherit"} component={"span"}>
                          {formatWithCommas(
                            displayPrices(Number(wallet_cash.toFixed(0)))
                          )}{" "}
                          Social Cash
                        </Typography>
                      </Typography>
                    </Box>
                    <Box sx={{ my: "10px" }}>
                      <Typography
                        fontSize={14}
                        fontWeight={"medium"}
                        sx={{
                          color: "#959595",
                          "@media (max-width: 390px)": {
                            fontSize: "12px", // Example adjustment for small screens
                          },
                        }}
                      >
                        Use{" "}
                        {displayPrices(
                          getEquivalentSosocial(
                            maxBill,
                            user?.soSocialConversion
                          )
                        )}{" "}
                        Social Cash on this bill.
                      </Typography>{" "}
                    </Box>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Grid item xs={5}>
                        <Typography
                          fontSize={14}
                          fontWeight={"medium"}
                          sx={{
                            color: "#959595",
                            "@media (max-width: 390px)": {
                              fontSize: "12px", // Example adjustment for small screens
                            },
                          }}
                        >
                          Bill Amount:{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          fontSize={14}
                          fontWeight={"medium"}
                          sx={{
                            color: "#959595",
                            "@media (max-width: 390px)": {
                              fontSize: "12px", // Example adjustment for small screens
                            },
                          }}
                        >
                          {formatWithCommas(
                            displayPrices(
                              calcWithServiceFees({
                                total: currentDue,
                                serviceFee: conceptOptions?.serviceFee!,
                              }).total
                            )
                          )}{" "}
                          AED{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          fontSize={14}
                          fontWeight={"medium"}
                          sx={{
                            color: "#959595",
                            "@media (max-width: 390px)": {
                              fontSize: "12px", // Example adjustment for small screens
                            },
                            textAlign: "end",
                          }}
                        >
                          (excluding tips)
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item xs={5}>
                        <Typography
                          fontSize={14}
                          fontWeight={"bold"}
                          sx={{
                            color: "#959595",
                            "@media (max-width: 390px)": {
                              fontSize: "12px", // Example adjustment for small screens
                            },
                          }}
                        >
                          Amount Payable:
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          fontSize={14}
                          fontWeight={"bold"}
                          sx={{
                            color: "#959595",
                            "@media (max-width: 390px)": {
                              fontSize: "12px", // Example adjustment for small screens
                            },
                          }}
                        >
                          {displayPrices(
                            calcWithServiceFees({
                              total: currentDue,
                              serviceFee: conceptOptions?.serviceFee!,
                            }).total - maxBill
                          )}
                          {" AED"}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          fontSize={14}
                          fontWeight={"medium"}
                          sx={{
                            color: "#959595",
                            "@media (max-width: 390px)": {
                              fontSize: "12px", // Example adjustment for small screens
                            },
                            textAlign: "end",
                          }}
                        >
                          (excluding tips)
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        if (
                          user?.wallet.amount! >
                          getEquivalentSosocial(
                            maxBill,
                            user?.soSocialConversion
                          )
                        ) {
                          setCash(
                            getEquivalentSosocial(
                              maxBill,
                              user?.soSocialConversion
                            )
                          );
                        } else {
                          setCash(user?.wallet.amount!);
                        }
                      }}
                      variant="contained"
                      sx={{
                        ...classes.useAmountButton,
                        "@media (max-width: 390px)": {
                          fontSize: "10px", // Example adjustment for small screens
                          padding: "12px 8px", // Adjust padding for smaller screens
                        },
                      }}
                      disabled={Boolean(Number(user?.wallet.amount) < 1)}
                    >
                      Proceed
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        ...classes.customAmountButton,
                        "@media (max-width: 390px)": {
                          fontSize: "10px", // Example adjustment for small screens
                          padding: "12px 8px", // Adjust padding for smaller screens
                        },
                      }}
                      onClick={() => setCustomInput(true)}
                      disabled={Boolean(Number(user?.wallet.amount) < 1)}
                    >
                      Enter Custom Amount
                      {/* <Box
                        sx={{
                          width: "18px",
                          height: "18px",
                          marginLeft: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          border:
                            "2px solid " + lightTheme.palette.secondary.main,
                          borderRadius: "50%",
                          position: "absolute",
                        }}
                      >
                        <FaPencilAlt
                          color={lightTheme.palette.secondary.main}
                          size={"10px"}
                        />
                      </Box> */}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Slide>
      </Backdrop>
    </>
  );
}
const classes = {
  containerBox: {
    boxSizing: "border-box",
    position: "relative",
    width: "100%",
    background: "#FFFFFF",
    padding: "35px",
    borderRadius: "50px 50px 0px 0px",
    boxShadow: "0px 0px 1px 0px",
  },
  containerGrid: {
    height: "100%",
    gap: "1rem",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
  },
  useAmountButton: {
    backgroundColor: "black",
    textTransform: "unset",
    fontSize: "12px",
    fontWeight: "medium",
    borderRadius: "32px",
    py: "13px",
    width: "48%",
    "&:hover": {
      backgroundColor: "black",
    },
  },
  customAmountButton: {
    backgroundColor: "transparent",
    textTransform: "unset",
    fontSize: "12px",
    fontWeight: "medium",
    borderRadius: "32px",
    color: lightTheme.palette.secondary.main,
    py: "10px",
    width: "48%",
    boxShadow: "none",
    border: "1px solid #C4C4C4",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
};
export default RedemptionModal;
