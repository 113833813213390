import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Rating,
  Slide,
  SxProps,
  Typography,
  createTheme,
} from "@mui/material";
import { GoGift } from "react-icons/go";
import { FaRegCheckCircle } from "react-icons/fa";
import { FaRegCircleXmark } from "react-icons/fa6";
import lightThemeOptions from "../../theme/lightThemeOptions";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/ducks";
import { useEffect, useState } from "react";
import { submitOrderRating } from "../../services/order";
const lightTheme = createTheme(lightThemeOptions);

type Props = {
  open: boolean;
  successful: boolean;
  onClose: () => void;
  pay: () => void;
  loading: boolean;
  earnings: number;
  paymentData: any;
};
function PaymentDone({
  open,
  onClose,
  successful,
  pay,
  loading,
  earnings,
  paymentData,
}: Props) {
  const [rating, setRating] = useState<number | null>(null);
  const { paymentType, order, currentDue } = useSelector(
    (state: StoreState) => state.order
  );

  const onRatingChange = (_: any, newValue: number | null) => {
    setRating(newValue);
  };

  const handleClose = () => {
    onClose();
  };


  // useEffect(() => {
  //   //rate order request
  //   const submitRate = async () => {
  //     try {
  //       const res = await submitOrderRating(
  //         rating
  //           ? order && order.ratings
  //             ? [...order?.ratings, rating]
  //             : [rating]
  //           : [],
  //         (order && order.id) || ""
  //       );
  //       console.log(res);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   submitRate();
  // }, [rating]);

  return (
    <>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 0,
          alignItems: "flex-end",
        }}
        onClick={(e) => handleClose()}
        open={open}
      >
        <Slide direction="up" in={open} mountOnEnter unmountOnExit>
          <Box sx={classes.containerBox} onClick={(e) => e.stopPropagation()}>
            <Grid container sx={classes.containerGrid}>
              <Box
                sx={{
                  backgroundColor: "white",
                  width: "130px",
                  height: "130px",
                  borderRadius: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: "-70px",
                  filter: "drop-shadow(0px 3px 6px #00000029)",
                }}
              >
                {successful ? (
                  <FaRegCheckCircle
                    fontSize="80px"
                    stroke="2px"
                    color={lightTheme.palette.secondary.light}
                  />
                ) : (
                  <FaRegCircleXmark
                    fontSize="80px"
                    color={lightTheme.palette.secondary.main}
                  />
                )}
              </Box>
              <Grid item xs={12} sx={classes.logoContainer}></Grid>
              {successful ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <Typography
                    fontSize={"15px"}
                    fontWeight={"bold"}
                    color={"secondary"}
                  >
                    Payment Successful
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={"medium"}
                    color={"primary"}
                    textAlign={"center"}
                  >
                    Thank you, your payment has been successful. Check the below
                    order details.
                  </Typography>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <Typography
                    fontSize={"15px"}
                    fontWeight={"bold"}
                    color={"secondary"}
                  >
                    Payment Failed
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontWeight={"medium"}
                    color={"primary"}
                    textAlign={"center"}
                  >
                    Please try paying your bill again
                  </Typography>
                </Grid>
              )}
              {!successful && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "5px",
                    mt: "25px",
                  }}
                >
                  <Button
                    startIcon={loading && <CircularProgress size={20} />}
                    sx={classes.finalPayment}
                    onClick={pay}
                    disabled={loading}
                  >
                    Try Again
                  </Button>
                </Grid>
              )}
              {successful && earnings !== 0 && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    // height: "3px",
                    backgroundColor: "#f6f6f6",
                    borderRadius: "15px",
                    display: "flex",
                    justifyContent: "center",
                    gap: "5px",
                    padding: "15px",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <GoGift fontSize={"26px"} />
                  </Box>
                  <Typography
                    fontSize={"15px"}
                    fontWeight={"medium"}
                    color={"secondary"}
                  >
                    You have earned&nbsp;
                    <Typography
                      fontSize={"15px"}
                      fontWeight={"bold"}
                      color={"secondary"}
                      component={"span"}
                    >
                      {earnings}&nbsp;
                    </Typography>
                    SoSocial Cash
                  </Typography>
                </Grid>
              )}
              {successful && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <Typography
                    fontSize={"15px"}
                    fontWeight={"bold"}
                    color={"secondary"}
                    textAlign={"left"}
                  >
                    Order Details
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: "#f6f6f6",
                      borderRadius: "15px",
                      padding: "25px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <Typography fontSize={14} fontWeight={"bold"}>
                        Order ID:
                      </Typography>
                      <Typography
                        fontSize={14}
                        fontWeight={"medium"}
                        color="#909090"
                      >
                        {order?.CheckId}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <Typography fontSize={14} fontWeight={"bold"}>
                        Order Date:
                      </Typography>
                      <Typography
                        fontSize={14}
                        fontWeight={"medium"}
                        color="#909090"
                      >
                        {order &&
                          order.createdAt &&
                          new Date(order.createdAt).toDateString()}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <Typography fontSize={14} fontWeight={"bold"}>
                        Paid Amount:
                      </Typography>
                      <Typography
                        fontSize={14}
                        fontWeight={"medium"}
                        color="#909090"
                      >
                        {paymentData ? paymentData.amount : 0} AED
                        {/* {currentDue.toFixed(2)} AED */}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <Typography fontSize={14} fontWeight={"bold"}>
                        Cheque Status:
                      </Typography>
                      <Typography
                        fontSize={14}
                        fontWeight={"medium"}
                        color="#909090"
                      >
                        {paymentType === "full"
                          ? "Fully Paid"
                          : "Partially Paid"}
                      </Typography>
                    </Box>
                  </Box>
                  {/* Rating box */}
                  <Box
                    sx={{
                      mt: 2,
                      backgroundColor: "#f6f6f6",
                      borderRadius: "15px",
                      border: "1px solid #e3e3e3",
                      padding: "25px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Don't forget to rate your experience
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                      }}
                    >
                      We would love to hear about it
                    </Typography>
                    <Rating
                      size="large"
                      value={rating}
                      onChange={onRatingChange}
                      disabled={Boolean(rating)}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Slide>
      </Backdrop>
    </>
  );
}
const classes = {
  containerBox: {
    boxSizing: "border-box",
    position: "relative",
    width: "100%",
    background: "#FFFFFF",
    padding: {
      xs: "35px",
      sm: "1rem",
      md: "1.5rem",
    },
    borderRadius: "50px 50px 0px 0px",
    maxWidth: "500px",
  },
  containerGrid: {
    height: "100%",
    gap: "1rem",
    alignItems: "center",
    justifyContent: "center",
  },
  logoContainer: {
    marginTop: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "128px",
  } as SxProps,
  finalPayment: {
    backgroundColor: "black",
    textTransform: "unset",
    fontSize: "15px",
    fontWeight: "bold",
    borderRadius: "32px",
    mb: "5px",
    py: "10px",
    "&:hover": {
      backgroundColor: "black",
    },
    "&:disabled": {
      backgroundColor: "gray",
      color: "#3a3a3a",
    },
  } as SxProps,
};
export default PaymentDone;
