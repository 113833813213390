import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconContainerProps,
  Rating,
  Slide,
  SxProps,
  TextField,
  Typography,
  createTheme,
  styled,
} from "@mui/material";

import lightThemeOptions from "../../theme/lightThemeOptions";
import { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { t } from "i18next";
import { toast } from "react-toastify";
import { submitOrderRating } from "../../services/order";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
const lightTheme = createTheme(lightThemeOptions);

type Props = {
  open: boolean;
  onClose: () => void;
  rating: number | null;
  bank_receipt_no: string;
  merchant_param2: string;
  handleSuccessfulRating: (fullRated: boolean) => void;
};
function RatingsModal({
  open,
  onClose,
  rating,
  bank_receipt_no,
  merchant_param2,
  handleSuccessfulRating,
}: Props) {
  const [localRating, setLocalRating] = useState<number | null>(rating);
  const [review, setReview] = useState("");

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setLocalRating(rating);
  }, [rating]);

  const onRatingChange = (_: any, newValue: number | null) => {
    setLocalRating(newValue);
  };

  const handleReviewChange = (event: any) => {
    if (loadingRating) return;
    setReview(event.target.value);
  };
  const submitRate = async () => {
    try {
      setLoadingRating(true);
      let fullrated = false;
      if (rating) {
        const res = await submitOrderRating(
          rating,
          review,
          bank_receipt_no + Date.now(),
          merchant_param2
        );
        setRated(rating);
        if (rating === 5) {
          fullrated = true;
        }
        handleSuccessfulRating(fullrated);
      }
    } catch (error) {
      console.log("error submitting ratings" + error);
      toast.error("Error submitting your rating");
    } finally {
      setLoadingRating(false);
    }
  };
  const [loadingRating, setLoadingRating] = useState(false);
  const [rated, setRated] = useState<number | null>(null);

  const StyledRating = styled(Rating)(({ theme }) => ({
    "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
      color: "grey",
    },
  }));
  const customIcons: {
    [index: string]: {
      icon: React.ReactElement;
      label: string;
    };
  } = {
    1: {
      icon: <SentimentDissatisfiedIcon fontSize="large" color="error" />,
      label: "Dissatisfied",
    },
    2: {
      icon: <SentimentSatisfiedIcon fontSize="large" color="warning" />,
      label: "Neutral",
    },
    3: {
      icon: <SentimentSatisfiedAltIcon fontSize="large" color="success" />,
      label: "Satisfied",
    },
  };

  function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }
  return (
    <>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 0,
          alignItems: "flex-end",
        }}
        open={open}
        onClick={(e) => handleClose()}
      >
        <Slide in={open} direction="up">
          <Box sx={classes.containerBox} onClick={(e) => e.stopPropagation()}>
            <Grid container sx={classes.containerGrid}>
              <Grid
                item
                sx={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  position: "relative",
                }}
                xs={12}
              >
                <Box sx={{ width: "100%", position: "absolute" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "start",
                    }}
                  >
                    <IoIosCloseCircleOutline
                      color="red"
                      onClick={handleClose}
                      size={"24px"}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <StyledRating
                    value={rating}
                    onChange={onRatingChange}
                    disabled={loadingRating}
                    name="highlight-selected-only"
                    defaultValue={1}
                    IconContainerComponent={IconContainer}
                    getLabelText={(value: number) => ""}
                    highlightSelectedOnly
                    max={3}
                    size="large"
                  />
                  {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "16px",
                      width: "60%",
                      mt: "5px",
                    }}
                  >
                    <Typography fontSize={14}>Not Satisfied</Typography>
                    <Typography fontSize={14}>Satisfied</Typography>
                  </Box> */}
                </Box>
                <Divider orientation="horizontal" sx={{ width: "110%" }} />
                <Box sx={{ width: "100%" }}>
                  <TextField
                    disabled={loadingRating || Boolean(rated)}
                    id="outlined-basic"
                    variant="outlined"
                    placeholder={t("ShareThoughts")}
                    fullWidth
                    multiline
                    rows={3}
                    value={review}
                    onChange={handleReviewChange}
                    sx={{
                      "& .MuiInputBase-root": {
                        width: "100%",
                        margin: "1rem auto",
                        borderWidth: "0.5px",
                        borderStyle: "solid",
                        borderColor: "white",
                        borderRadius: "10px",
                        background: "white",
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "5px",
                  mt: "25px",
                }}
              >
                {rated ? (
                  <Typography sx={{ fontSize: "14px" }}>
                    Thank you for your feedback! 🥰
                  </Typography>
                ) : (
                  <Button
                    startIcon={loadingRating && <CircularProgress size={20} />}
                    sx={classes.finalPayment}
                    onClick={submitRate}
                    disabled={loadingRating}
                  >
                    {t("Submit")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Slide>
      </Backdrop>
    </>
  );
}
const classes = {
  containerBox: {
    boxSizing: "border-box",
    position: "relative",
    width: "100%",
    background: "#FFFFFF",
    padding: {
      xs: "35px",
      sm: "1rem",
      md: "1.5rem",
    },
    borderRadius: "50px 50px 0px 0px",
  },
  containerGrid: {
    height: "100%",
    gap: "1rem",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
  },
  walletTitle: {
    fontWeight: "bold",
    fontSize: "15px",
    color: lightTheme.palette.secondary.main,
    mb: "16px",
  },
  walletSubtitle: {
    fontWeight: "medium",
    fontSize: "14px",
    color: lightTheme.palette.secondary.main,
    width: "100%",
  },
  authContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  authenticateButton: {
    backgroundColor: "black",
    width: "80%",
    textTransform: "unset",
    fontSize: "14px",
    fontWeight: "bold",
    borderRadius: "32px",
    my: "18px",
    py: "10px",
    "&:hover": {
      backgroundColor: "black",
    },
    "&:disabled": {
      backgroundColor: "gray",
      color: "#3a3a3a",
    },
  },
  useAmountButton: {
    backgroundColor: "black",
    textTransform: "unset",
    fontSize: "12px",
    fontWeight: "medium",
    borderRadius: "32px",
    py: "13px",
    width: "48%",
    "&:hover": {
      backgroundColor: "black",
    },
  },
  customAmountButton: {
    backgroundColor: "transparent",
    textTransform: "unset",
    fontSize: "12px",
    fontWeight: "medium",
    borderRadius: "32px",
    color: lightTheme.palette.secondary.main,
    py: "10px",
    width: "48%",
    boxShadow: "none",
    border: "1px solid #C4C4C4",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  textField: {
    "& svg": { height: "1em" },
    "& .MuiOutlinedInput-root": {
      "& .MuiInputBase-input": {
        padding: "10px",
        px: "19px",
        color: lightTheme.palette.secondary.main,
        fontSize: "15px",
        fontWeight: "bold",
      },
      "& fieldset": {
        borderRadius: "32px",
        borderColor: "transparent !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
    },
  },
  finalPayment: {
    backgroundColor: "black",
    textTransform: "unset",
    fontSize: "15px",
    fontWeight: "bold",
    borderRadius: "32px",
    mb: "5px",
    py: "10px",
    "&:hover": {
      backgroundColor: "black",
    },
    "&:disabled": {
      backgroundColor: "gray",
      color: "#3a3a3a",
    },
    minWidth: "250px",
  } as SxProps,
};
export default RatingsModal;
