import { LamdaFnLinks } from "../constants/enums";
import { TableManagementOperationIds } from "../constants/operationIds";
import axios from "axios";
import { ServiceFee, Table, Tip } from "../models/API";

interface GetTableReturn extends Table {
  concept: {
    id: string;
    name: string;
    tips: Tip[];
    serviceFee: ServiceFee;
    pos_id: string;
    logo: string;
    mainBg: string;
    soSocialEarnRatio: number;
    soSocialRedeemRatio: number;
    discountObjectNum: string;
    EnableLoyalty: Boolean;
    applePayMerchantIdentifier: string;
  };
}
export async function getTableByCode(code: string) {
  try {
    const response = await axios.post(LamdaFnLinks.TableManagement, {
      operationId: TableManagementOperationIds.GET_TABLE_BY_CODE,
      tableCode: code,
    });
    const data = response.data;
    return data as GetTableReturn;
  } catch (error) {
    console.error(error);
    return null;
  }
}
