import { LamdaFnLinks } from "../constants/enums";
import { TableManagementOperationIds } from "../constants/operationIds";
import axios from "axios";
import { MainCategory, ServiceFee, Table, Tip } from "../models/API";

interface getCategoriesReturn {
  items: MainCategory[];
}
export async function getMainCategories(conceptID: string | undefined) {
  try {
    if (!conceptID) {
      throw new Error("No Concept ID Found");
    }
    const response = await axios.post(LamdaFnLinks.TableManagement, {
      operationId: TableManagementOperationIds.GET_MAIN_CATEGORIES,
      conceptID,
    });
    const data = response.data;
    return data as getCategoriesReturn;
  } catch (error) {
    console.error(error);
    return null;
  }
}
