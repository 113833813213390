import React, { FC, PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/ducks";
import { Navigate, useParams } from "react-router-dom";

const RedirectHome: FC<PropsWithChildren> = ({ children }) => {
  const conceptOptions = useSelector(
    (state: StoreState) => state.app.conceptOptions
  );
  const params = useParams();

  // CCAvenue Payment
  const queryParams = new URLSearchParams(window.location.search);
  const info = queryParams.get("info");

  if (!conceptOptions) {
    /*
     * If there is no concept but there is payment info
     * Load order info then redirect
     */

    if (info) {
      return <>{children}</>;
    } else {
      return <Navigate to={params.id ? `/${params.id}` : "/"} />;
    }
  } else {
    return <>{children}</>;
  }
};

export default RedirectHome;
