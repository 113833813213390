import axios from "axios";
import { PaymentOperationIds } from "../constants/operationIds";
import { LamdaFnLinks } from "../constants/enums";

export async function validateMerchant(
  concept: any,
  merchantIdentifier: string,
  initiativeContext: string
) {
  try {
    const response = await axios.post(
      LamdaFnLinks.PaymentOperations +
        `?operation=${PaymentOperationIds.APPLE_SESSION}`,
      {
        conceptID: concept ? concept.id : "",
        merchantIdentifier,
        initiativeContext,
      }
    );
    return response.data;
  } catch (error) {}
}
