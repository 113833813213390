import { LamdaFnLinks } from "../constants/enums";
import { TableManagementOperationIds } from "../constants/operationIds";
import axios from "axios";
import { ServiceFee, Table, Tip } from "../models/API";

interface GetPendingPaymentsReturn {
  totalPending: number;
}
interface GetPendingPaymentsRequestBody {
  CheckNum: string;
  tableId: string;
  conceptID: string;
}
export async function getPendingPayments(
  requestBody: GetPendingPaymentsRequestBody
) {
  try {
    const response = await axios.post(LamdaFnLinks.TableManagement, {
      operationId: TableManagementOperationIds.GET_PENDING_PAYMENTS,
      ...requestBody,
    });
    const data = response.data;
    console.log({ data });
    return data as GetPendingPaymentsReturn;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
