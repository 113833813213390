import { Box, BoxProps, SxProps } from "@mui/material";
import { FC } from "react";
import soSocialLogoYellow from "../../assets/tiki/SoSocialLogo.webp";
import soSocialLogoBlack from "../../assets/tiki/soSocialMinified.webp";

import {
  soSocialAppStore,
  soSocialGooglePlay,
} from "../../constants/constants";
import { detectDevice } from "../../helpers/utils";

// const logos = {
//   primary: require(`../../assets/tiki/logo-primary.png`),
//   light: require(`../../assets/tiki/logo-light.png`),
//   dark: require(`../../assets/tiki/logo-dark.png`),
// };
interface LogoProps {
  sx?: SxProps;
  boxProps?: BoxProps;
  background: Boolean;
}

const SoSocialLogo: FC<LogoProps> = ({ sx, boxProps, background }) => {
  const handleClick = () => {
    if (detectDevice(navigator.userAgent) === "iOS") {
      window.open(soSocialAppStore, "_blank", "noopener,noreferrer");
    } else {
      window.open(soSocialGooglePlay, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <Box
      {...boxProps}
      component="img"
      alt="logo"
      src={
        background
          ? "https://qrsolutionbucket130835-production.s3.us-east-2.amazonaws.com/public/sosocialminified-1721585757726.webp"
          : soSocialLogoYellow
      }
      sx={sx}
      onClick={handleClick}
    />
  );
};

export default SoSocialLogo;
