import axios, { AxiosError } from "axios";
import { PayOrderReturnType, PaymentType } from "./order";
import { LamdaFnLinks } from "../constants/enums";
import { SimphonyOperationIds } from "../constants/operationIds";

export async function sendEmail({
  conceptId,
  tableSimphonyId,
  CheckNum,
  CheckSeq,
  payment,
  usedCash,
}: {
  tableSimphonyId: string;
  conceptId: string;
  CheckNum: string;
  CheckSeq: string;
  payment: PaymentType;
  usedCash: Number;
}): Promise<PayOrderReturnType> {
  try {
    console.log({ usedCash });

    const response = await axios.post(LamdaFnLinks.SimphonyOperations, {
      operation: SimphonyOperationIds.SEND_EMAIL,
      conceptID: conceptId,
      CheckNum,
      CheckSeq,
      tableID: tableSimphonyId,
      payment,
      usedCash,
    });

    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    if ((error as AxiosError).response?.data) {
      return (error as AxiosError).response?.data as PayOrderReturnType;
    } else {
      return {
        success: false,
        message: "Something went wrong. Please try again.",
      };
    }
  }
}
