import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import Logo from "../../components/Logo";
import MenusListItem from "../../components/MenusListItem";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import TakeoutDiningIcon from "@mui/icons-material/TakeoutDining";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useDispatch, useSelector } from "react-redux";
import { setPDF } from "../../store/ducks/app";
import { useEffect, useState } from "react";
import { getMainCategories } from "../../services/menu";
import { StoreState } from "../../store/ducks";
import { MainCategory } from "../../models/API";
import { toast } from "react-toastify";
import BrunchDiningIcon from "@mui/icons-material/BrunchDining";
import SmokingRoomsRoundedIcon from "@mui/icons-material/SmokingRoomsRounded";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
const classes = {
  Header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
  },
  backButton: {
    cursor: "pointer",
    position: "absolute",
    left: "25px",
    color: (theme: any) => theme.palette.primary.main,
  },
};
const getIcon = (menuName: string) => {
  if (menuName.includes("food") && menuName.includes("beverage")) {
    return <BrunchDiningIcon sx={{ color: "#602d08" }} />;
  } else if (menuName.includes("food")) {
    return <TakeoutDiningIcon sx={{ color: "#602d08" }} />;
  } else if (
    menuName.toLowerCase().includes("beverage") ||
    menuName.toLowerCase().includes("wine") ||
    menuName.toLowerCase().includes("cocktail")
  ) {
    return <LocalBarIcon sx={{ color: "#602d08" }} />;
  } else if (menuName.includes("shisha")) {
    return <SmokingRoomsRoundedIcon sx={{ color: "#602d08" }} />;
  } else if (menuName.includes("hour") || menuName.includes("day")) {
    return <QueryBuilderOutlinedIcon sx={{ color: "#602d08" }} />;
  } else {
    return <BrunchDiningIcon sx={{ color: "#602d08" }} />;
  }
};
const MenusPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const concept = useSelector((state: StoreState) => state.app.conceptOptions);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<MainCategory[]>([]);
  const handlePDF = (index: number) => {
    const pdfName = categories[index].menuPDF;
    dispatch(setPDF(pdfName));
    navigate("pdfView", { state: { pdf: pdfName } });
  };

  useEffect(() => {
    const fetchMainCategories = async () => {
      try {
        setLoading(true);
        const categories = await getMainCategories(concept?.id ?? "");
        if (categories) {
          setCategories(categories.items);
        } else {
          setCategories([]);
          throw new Error("No Categories Found");
        }
      } catch (error) {
        toast.error("Error Getting Categories");
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchMainCategories();
  }, []);

  return (
    <Box
      sx={{
        minHeight: "95svh",
        pt: 4,
      }}
    >
      <Grid item sx={classes.Header}>
        <ArrowBackIosNewIcon
          sx={classes.backButton}
          onClick={() => navigate(-1)}
        />
        <Box
          sx={{
            backgroundColor: "white",
            width: "130px",
            height: "130px",
            borderRadius: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "0px",
            filter: "drop-shadow(0px 3px 6px #00000029)",
            overflow: "hidden",
          }}
        >
          <Logo
            color="primary"
            sx={{
              cursor: "pointer",
              width: "100%",
            }}
          />
        </Box>
      </Grid>
      {/* <Box
        sx={{
          width: "fit-content",
          height: "fit-content",
          mx: "auto",
        }}
      >
        <Logo color="primary" sx={{ width: "170px", margin: "auto" }} />
      </Box> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 2,
          p: 3,
        }}
      >
        {loading ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              height: "30vh",
              alignItems: "center",
            }}
          >
            <CircularProgress color="primary" size={64} />
          </Box>
        ) : categories.length > 0 ? (
          categories.map((item, index) => (
            <MenusListItem
              key={`${item.id}`}
              title={item.name}
              icon={getIcon(item.name)}
              onClick={() => handlePDF(index)}
            />
          ))
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              height: "30vh",
              alignItems: "center",
            }}
          >
            <Typography fontSize={24} color={"#602d08"} fontWeight={"bold"}>
              No Menus Yet
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MenusPage;
