/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../models/API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateAccount = /* GraphQL */ `subscription OnCreateAccount($filter: ModelSubscriptionAccountFilterInput) {
  onCreateAccount(filter: $filter) {
    id
    logo {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    mainBg {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    domain
    siteTitle
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    accountLogoId
    accountMainBgId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAccountSubscriptionVariables,
  APITypes.OnCreateAccountSubscription
>;
export const onUpdateAccount = /* GraphQL */ `subscription OnUpdateAccount($filter: ModelSubscriptionAccountFilterInput) {
  onUpdateAccount(filter: $filter) {
    id
    logo {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    mainBg {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    domain
    siteTitle
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    accountLogoId
    accountMainBgId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAccountSubscriptionVariables,
  APITypes.OnUpdateAccountSubscription
>;
export const onDeleteAccount = /* GraphQL */ `subscription OnDeleteAccount($filter: ModelSubscriptionAccountFilterInput) {
  onDeleteAccount(filter: $filter) {
    id
    logo {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    mainBg {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      __typename
    }
    domain
    siteTitle
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    accountLogoId
    accountMainBgId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAccountSubscriptionVariables,
  APITypes.OnDeleteAccountSubscription
>;
export const onCreateConcept = /* GraphQL */ `subscription OnCreateConcept($filter: ModelSubscriptionConceptFilterInput) {
  onCreateConcept(filter: $filter) {
    id
    accountID
    name
    description
    logo
    mainBg
    type
    location
    precedence
    longitude
    latitude
    openingHours
    closingHours
    posID
    simphonyIP
    rvcIP
    simphonyEmployeeID
    tenderID
    visaTenderID
    applePayTenderID
    samsungPayTenderID
    tipsNum
    CheckOrderType
    emailTemplateName
    discountObjectNum
    secondaryTender
    EnableLoyalty
    emailName
    emailLocation
    emailPhone
    tips {
      id
      amount
      type
      default
      __typename
    }
    serviceFee {
      amount
      type
      __typename
    }
    soSocialEarnRatio
    soSocialRedeemRatio
    paymentMerchants {
      channel
      merchant_id
      access_code
      enc_key
      url
      env
      __typename
    }
    refundMerchants {
      channel
      merchant_id
      access_code
      enc_key
      url
      env
      __typename
    }
    applePayMerchantIdentifier
    isMPGS
    retryAfter
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateConceptSubscriptionVariables,
  APITypes.OnCreateConceptSubscription
>;
export const onUpdateConcept = /* GraphQL */ `subscription OnUpdateConcept($filter: ModelSubscriptionConceptFilterInput) {
  onUpdateConcept(filter: $filter) {
    id
    accountID
    name
    description
    logo
    mainBg
    type
    location
    precedence
    longitude
    latitude
    openingHours
    closingHours
    posID
    simphonyIP
    rvcIP
    simphonyEmployeeID
    tenderID
    visaTenderID
    applePayTenderID
    samsungPayTenderID
    tipsNum
    CheckOrderType
    emailTemplateName
    discountObjectNum
    secondaryTender
    EnableLoyalty
    emailName
    emailLocation
    emailPhone
    tips {
      id
      amount
      type
      default
      __typename
    }
    serviceFee {
      amount
      type
      __typename
    }
    soSocialEarnRatio
    soSocialRedeemRatio
    paymentMerchants {
      channel
      merchant_id
      access_code
      enc_key
      url
      env
      __typename
    }
    refundMerchants {
      channel
      merchant_id
      access_code
      enc_key
      url
      env
      __typename
    }
    applePayMerchantIdentifier
    isMPGS
    retryAfter
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateConceptSubscriptionVariables,
  APITypes.OnUpdateConceptSubscription
>;
export const onDeleteConcept = /* GraphQL */ `subscription OnDeleteConcept($filter: ModelSubscriptionConceptFilterInput) {
  onDeleteConcept(filter: $filter) {
    id
    accountID
    name
    description
    logo
    mainBg
    type
    location
    precedence
    longitude
    latitude
    openingHours
    closingHours
    posID
    simphonyIP
    rvcIP
    simphonyEmployeeID
    tenderID
    visaTenderID
    applePayTenderID
    samsungPayTenderID
    tipsNum
    CheckOrderType
    emailTemplateName
    discountObjectNum
    secondaryTender
    EnableLoyalty
    emailName
    emailLocation
    emailPhone
    tips {
      id
      amount
      type
      default
      __typename
    }
    serviceFee {
      amount
      type
      __typename
    }
    soSocialEarnRatio
    soSocialRedeemRatio
    paymentMerchants {
      channel
      merchant_id
      access_code
      enc_key
      url
      env
      __typename
    }
    refundMerchants {
      channel
      merchant_id
      access_code
      enc_key
      url
      env
      __typename
    }
    applePayMerchantIdentifier
    isMPGS
    retryAfter
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteConceptSubscriptionVariables,
  APITypes.OnDeleteConceptSubscription
>;
export const onCreateUserConcepts = /* GraphQL */ `subscription OnCreateUserConcepts(
  $filter: ModelSubscriptionUserConceptsFilterInput
) {
  onCreateUserConcepts(filter: $filter) {
    id
    defaultConcept
    concepts
    conceptsRoles
    deleted
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserConceptsSubscriptionVariables,
  APITypes.OnCreateUserConceptsSubscription
>;
export const onUpdateUserConcepts = /* GraphQL */ `subscription OnUpdateUserConcepts(
  $filter: ModelSubscriptionUserConceptsFilterInput
) {
  onUpdateUserConcepts(filter: $filter) {
    id
    defaultConcept
    concepts
    conceptsRoles
    deleted
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserConceptsSubscriptionVariables,
  APITypes.OnUpdateUserConceptsSubscription
>;
export const onDeleteUserConcepts = /* GraphQL */ `subscription OnDeleteUserConcepts(
  $filter: ModelSubscriptionUserConceptsFilterInput
) {
  onDeleteUserConcepts(filter: $filter) {
    id
    defaultConcept
    concepts
    conceptsRoles
    deleted
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserConceptsSubscriptionVariables,
  APITypes.OnDeleteUserConceptsSubscription
>;
export const onCreateAttachment = /* GraphQL */ `subscription OnCreateAttachment(
  $filter: ModelSubscriptionAttachmentFilterInput
) {
  onCreateAttachment(filter: $filter) {
    id
    mediaID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAttachmentSubscriptionVariables,
  APITypes.OnCreateAttachmentSubscription
>;
export const onUpdateAttachment = /* GraphQL */ `subscription OnUpdateAttachment(
  $filter: ModelSubscriptionAttachmentFilterInput
) {
  onUpdateAttachment(filter: $filter) {
    id
    mediaID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAttachmentSubscriptionVariables,
  APITypes.OnUpdateAttachmentSubscription
>;
export const onDeleteAttachment = /* GraphQL */ `subscription OnDeleteAttachment(
  $filter: ModelSubscriptionAttachmentFilterInput
) {
  onDeleteAttachment(filter: $filter) {
    id
    mediaID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAttachmentSubscriptionVariables,
  APITypes.OnDeleteAttachmentSubscription
>;
export const onCreateFeature = /* GraphQL */ `subscription OnCreateFeature($filter: ModelSubscriptionFeatureFilterInput) {
  onCreateFeature(filter: $filter) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFeatureSubscriptionVariables,
  APITypes.OnCreateFeatureSubscription
>;
export const onUpdateFeature = /* GraphQL */ `subscription OnUpdateFeature($filter: ModelSubscriptionFeatureFilterInput) {
  onUpdateFeature(filter: $filter) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFeatureSubscriptionVariables,
  APITypes.OnUpdateFeatureSubscription
>;
export const onDeleteFeature = /* GraphQL */ `subscription OnDeleteFeature($filter: ModelSubscriptionFeatureFilterInput) {
  onDeleteFeature(filter: $filter) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFeatureSubscriptionVariables,
  APITypes.OnDeleteFeatureSubscription
>;
export const onCreateAdminRole = /* GraphQL */ `subscription OnCreateAdminRole($filter: ModelSubscriptionAdminRoleFilterInput) {
  onCreateAdminRole(filter: $filter) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAdminRoleSubscriptionVariables,
  APITypes.OnCreateAdminRoleSubscription
>;
export const onUpdateAdminRole = /* GraphQL */ `subscription OnUpdateAdminRole($filter: ModelSubscriptionAdminRoleFilterInput) {
  onUpdateAdminRole(filter: $filter) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAdminRoleSubscriptionVariables,
  APITypes.OnUpdateAdminRoleSubscription
>;
export const onDeleteAdminRole = /* GraphQL */ `subscription OnDeleteAdminRole($filter: ModelSubscriptionAdminRoleFilterInput) {
  onDeleteAdminRole(filter: $filter) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAdminRoleSubscriptionVariables,
  APITypes.OnDeleteAdminRoleSubscription
>;
export const onCreateAdminGroup = /* GraphQL */ `subscription OnCreateAdminGroup(
  $filter: ModelSubscriptionAdminGroupFilterInput
) {
  onCreateAdminGroup(filter: $filter) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAdminGroupSubscriptionVariables,
  APITypes.OnCreateAdminGroupSubscription
>;
export const onUpdateAdminGroup = /* GraphQL */ `subscription OnUpdateAdminGroup(
  $filter: ModelSubscriptionAdminGroupFilterInput
) {
  onUpdateAdminGroup(filter: $filter) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAdminGroupSubscriptionVariables,
  APITypes.OnUpdateAdminGroupSubscription
>;
export const onDeleteAdminGroup = /* GraphQL */ `subscription OnDeleteAdminGroup(
  $filter: ModelSubscriptionAdminGroupFilterInput
) {
  onDeleteAdminGroup(filter: $filter) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAdminGroupSubscriptionVariables,
  APITypes.OnDeleteAdminGroupSubscription
>;
export const onCreateMedia = /* GraphQL */ `subscription OnCreateMedia($filter: ModelSubscriptionMediaFilterInput) {
  onCreateMedia(filter: $filter) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMediaSubscriptionVariables,
  APITypes.OnCreateMediaSubscription
>;
export const onUpdateMedia = /* GraphQL */ `subscription OnUpdateMedia($filter: ModelSubscriptionMediaFilterInput) {
  onUpdateMedia(filter: $filter) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMediaSubscriptionVariables,
  APITypes.OnUpdateMediaSubscription
>;
export const onDeleteMedia = /* GraphQL */ `subscription OnDeleteMedia($filter: ModelSubscriptionMediaFilterInput) {
  onDeleteMedia(filter: $filter) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMediaSubscriptionVariables,
  APITypes.OnDeleteMediaSubscription
>;
export const onCreateTable = /* GraphQL */ `subscription OnCreateTable($filter: ModelSubscriptionTableFilterInput) {
  onCreateTable(filter: $filter) {
    id
    tableCode
    accountID
    conceptID
    simphonyID
    name
    status
    updatedAt
    deleted
    createdAt
    createdByID
    createdByName
    currentOrder {
      id
      accountID
      conceptID
      tableSimphonyID
      paymentID
      deleted
      createdAt
      updatedAt
      paid
      serviceFee
      tips
      paidAmount
      refundedAmount
      remainingAmount
      CheckNumber
      CheckSeqNumber
      SubTotal
      Tax
      totalOrderAmount
      Due
      Payment
      AdditionalPayment
      CheckId
      CheckOpenTime
      CheckCloseTime
      TableOpenTime
      MenuItems {
        DetailType
        DetailLink
        obj
        Total
        RevCtrID
        mTaxCollectedTotal
        Name
        SalesCount
        paidQTY
        totalAmount
        price
        __typename
      }
      DiscountItems {
        DetailType
        DetailLink
        obj
        Total
        RevCtrID
        mTaxCollectedTotal
        Name
        SalesCount
        paidQTY
        pcnt
        totalAmount
        price
        __typename
      }
      usedCash
      ratings {
        id
        rating
        __typename
      }
      __typename
    }
    lastClosedAt
    lastPaymentStatus
    lastUsedCash
    tableCurrentOrderId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTableSubscriptionVariables,
  APITypes.OnCreateTableSubscription
>;
export const onUpdateTable = /* GraphQL */ `subscription OnUpdateTable($filter: ModelSubscriptionTableFilterInput) {
  onUpdateTable(filter: $filter) {
    id
    tableCode
    accountID
    conceptID
    simphonyID
    name
    status
    updatedAt
    deleted
    createdAt
    createdByID
    createdByName
    currentOrder {
      id
      accountID
      conceptID
      tableSimphonyID
      paymentID
      deleted
      createdAt
      updatedAt
      paid
      serviceFee
      tips
      paidAmount
      refundedAmount
      remainingAmount
      CheckNumber
      CheckSeqNumber
      SubTotal
      Tax
      totalOrderAmount
      Due
      Payment
      AdditionalPayment
      CheckId
      CheckOpenTime
      CheckCloseTime
      TableOpenTime
      MenuItems {
        DetailType
        DetailLink
        obj
        Total
        RevCtrID
        mTaxCollectedTotal
        Name
        SalesCount
        paidQTY
        totalAmount
        price
        __typename
      }
      DiscountItems {
        DetailType
        DetailLink
        obj
        Total
        RevCtrID
        mTaxCollectedTotal
        Name
        SalesCount
        paidQTY
        pcnt
        totalAmount
        price
        __typename
      }
      usedCash
      ratings {
        id
        rating
        __typename
      }
      __typename
    }
    lastClosedAt
    lastPaymentStatus
    lastUsedCash
    tableCurrentOrderId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTableSubscriptionVariables,
  APITypes.OnUpdateTableSubscription
>;
export const onDeleteTable = /* GraphQL */ `subscription OnDeleteTable($filter: ModelSubscriptionTableFilterInput) {
  onDeleteTable(filter: $filter) {
    id
    tableCode
    accountID
    conceptID
    simphonyID
    name
    status
    updatedAt
    deleted
    createdAt
    createdByID
    createdByName
    currentOrder {
      id
      accountID
      conceptID
      tableSimphonyID
      paymentID
      deleted
      createdAt
      updatedAt
      paid
      serviceFee
      tips
      paidAmount
      refundedAmount
      remainingAmount
      CheckNumber
      CheckSeqNumber
      SubTotal
      Tax
      totalOrderAmount
      Due
      Payment
      AdditionalPayment
      CheckId
      CheckOpenTime
      CheckCloseTime
      TableOpenTime
      MenuItems {
        DetailType
        DetailLink
        obj
        Total
        RevCtrID
        mTaxCollectedTotal
        Name
        SalesCount
        paidQTY
        totalAmount
        price
        __typename
      }
      DiscountItems {
        DetailType
        DetailLink
        obj
        Total
        RevCtrID
        mTaxCollectedTotal
        Name
        SalesCount
        paidQTY
        pcnt
        totalAmount
        price
        __typename
      }
      usedCash
      ratings {
        id
        rating
        __typename
      }
      __typename
    }
    lastClosedAt
    lastPaymentStatus
    lastUsedCash
    tableCurrentOrderId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTableSubscriptionVariables,
  APITypes.OnDeleteTableSubscription
>;
export const onCreateOrder = /* GraphQL */ `subscription OnCreateOrder($filter: ModelSubscriptionOrderFilterInput) {
  onCreateOrder(filter: $filter) {
    id
    accountID
    conceptID
    tableSimphonyID
    paymentID
    deleted
    createdAt
    updatedAt
    paid
    serviceFee
    tips
    paidAmount
    refundedAmount
    remainingAmount
    CheckNumber
    CheckSeqNumber
    SubTotal
    Tax
    totalOrderAmount
    Due
    Payment
    AdditionalPayment
    CheckId
    CheckOpenTime
    CheckCloseTime
    TableOpenTime
    MenuItems {
      DetailType
      DetailLink
      obj
      Total
      RevCtrID
      mTaxCollectedTotal
      Name
      SalesCount
      paidQTY
      totalAmount
      price
      __typename
    }
    DiscountItems {
      DetailType
      DetailLink
      obj
      Total
      RevCtrID
      mTaxCollectedTotal
      Name
      SalesCount
      paidQTY
      pcnt
      totalAmount
      price
      __typename
    }
    usedCash
    ratings {
      id
      rating
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderSubscriptionVariables,
  APITypes.OnCreateOrderSubscription
>;
export const onUpdateOrder = /* GraphQL */ `subscription OnUpdateOrder($filter: ModelSubscriptionOrderFilterInput) {
  onUpdateOrder(filter: $filter) {
    id
    accountID
    conceptID
    tableSimphonyID
    paymentID
    deleted
    createdAt
    updatedAt
    paid
    serviceFee
    tips
    paidAmount
    refundedAmount
    remainingAmount
    CheckNumber
    CheckSeqNumber
    SubTotal
    Tax
    totalOrderAmount
    Due
    Payment
    AdditionalPayment
    CheckId
    CheckOpenTime
    CheckCloseTime
    TableOpenTime
    MenuItems {
      DetailType
      DetailLink
      obj
      Total
      RevCtrID
      mTaxCollectedTotal
      Name
      SalesCount
      paidQTY
      totalAmount
      price
      __typename
    }
    DiscountItems {
      DetailType
      DetailLink
      obj
      Total
      RevCtrID
      mTaxCollectedTotal
      Name
      SalesCount
      paidQTY
      pcnt
      totalAmount
      price
      __typename
    }
    usedCash
    ratings {
      id
      rating
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderSubscriptionVariables,
  APITypes.OnUpdateOrderSubscription
>;
export const onDeleteOrder = /* GraphQL */ `subscription OnDeleteOrder($filter: ModelSubscriptionOrderFilterInput) {
  onDeleteOrder(filter: $filter) {
    id
    accountID
    conceptID
    tableSimphonyID
    paymentID
    deleted
    createdAt
    updatedAt
    paid
    serviceFee
    tips
    paidAmount
    refundedAmount
    remainingAmount
    CheckNumber
    CheckSeqNumber
    SubTotal
    Tax
    totalOrderAmount
    Due
    Payment
    AdditionalPayment
    CheckId
    CheckOpenTime
    CheckCloseTime
    TableOpenTime
    MenuItems {
      DetailType
      DetailLink
      obj
      Total
      RevCtrID
      mTaxCollectedTotal
      Name
      SalesCount
      paidQTY
      totalAmount
      price
      __typename
    }
    DiscountItems {
      DetailType
      DetailLink
      obj
      Total
      RevCtrID
      mTaxCollectedTotal
      Name
      SalesCount
      paidQTY
      pcnt
      totalAmount
      price
      __typename
    }
    usedCash
    ratings {
      id
      rating
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderSubscriptionVariables,
  APITypes.OnDeleteOrderSubscription
>;
export const onCreatePaymentRequest = /* GraphQL */ `subscription OnCreatePaymentRequest(
  $filter: ModelSubscriptionPaymentRequestFilterInput
) {
  onCreatePaymentRequest(filter: $filter) {
    id
    usedCash
    user
    conceptId
    tableSimphonyId
    CheckNum
    CheckSeq
    payment {
      type
      amount
      billAmount
      tips
      serviceCharge
      items {
        DetailLink
        qty
        __typename
      }
      userEmail
      paymentMethod
      __typename
    }
    percentageArray
    soSocialConversion
    hasDiscounts
    discountAmount
    offerAmount
    familyGroupAmount
    isOffer
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePaymentRequestSubscriptionVariables,
  APITypes.OnCreatePaymentRequestSubscription
>;
export const onUpdatePaymentRequest = /* GraphQL */ `subscription OnUpdatePaymentRequest(
  $filter: ModelSubscriptionPaymentRequestFilterInput
) {
  onUpdatePaymentRequest(filter: $filter) {
    id
    usedCash
    user
    conceptId
    tableSimphonyId
    CheckNum
    CheckSeq
    payment {
      type
      amount
      billAmount
      tips
      serviceCharge
      items {
        DetailLink
        qty
        __typename
      }
      userEmail
      paymentMethod
      __typename
    }
    percentageArray
    soSocialConversion
    hasDiscounts
    discountAmount
    offerAmount
    familyGroupAmount
    isOffer
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePaymentRequestSubscriptionVariables,
  APITypes.OnUpdatePaymentRequestSubscription
>;
export const onDeletePaymentRequest = /* GraphQL */ `subscription OnDeletePaymentRequest(
  $filter: ModelSubscriptionPaymentRequestFilterInput
) {
  onDeletePaymentRequest(filter: $filter) {
    id
    usedCash
    user
    conceptId
    tableSimphonyId
    CheckNum
    CheckSeq
    payment {
      type
      amount
      billAmount
      tips
      serviceCharge
      items {
        DetailLink
        qty
        __typename
      }
      userEmail
      paymentMethod
      __typename
    }
    percentageArray
    soSocialConversion
    hasDiscounts
    discountAmount
    offerAmount
    familyGroupAmount
    isOffer
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePaymentRequestSubscriptionVariables,
  APITypes.OnDeletePaymentRequestSubscription
>;
export const onCreatePayment = /* GraphQL */ `subscription OnCreatePayment($filter: ModelSubscriptionPaymentFilterInput) {
  onCreatePayment(filter: $filter) {
    id
    accountID
    conceptID
    orderID
    tableID
    tableName
    channel
    tableOpenTime
    usedCash
    billAmount
    tipAmount
    serviceFees
    order_id
    tracking_id
    bank_ref_no
    order_status
    failure_message
    payment_mode
    card_name
    status_code
    status_message
    currency
    Amount
    billing_name
    billing_address
    billing_city
    billing_state
    billing_zip
    billing_country
    billing_tel
    billing_email
    merchant_param1
    merchant_param2
    merchant_param3
    merchant_param4
    merchant_param5
    merchant_param6
    mer_amount
    eci_value
    card_holder_name
    bank_receipt_no
    bank_qsi_no
    updatedSimphony
    updatedDBOrder
    errorMessage
    updatedSosocialWallet
    retryExecutionArn
    refunded
    refundedAmount
    percentageArray
    soSocialConversion
    earned
    isRegistered
    deleted
    CheckNum
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePaymentSubscriptionVariables,
  APITypes.OnCreatePaymentSubscription
>;
export const onUpdatePayment = /* GraphQL */ `subscription OnUpdatePayment($filter: ModelSubscriptionPaymentFilterInput) {
  onUpdatePayment(filter: $filter) {
    id
    accountID
    conceptID
    orderID
    tableID
    tableName
    channel
    tableOpenTime
    usedCash
    billAmount
    tipAmount
    serviceFees
    order_id
    tracking_id
    bank_ref_no
    order_status
    failure_message
    payment_mode
    card_name
    status_code
    status_message
    currency
    Amount
    billing_name
    billing_address
    billing_city
    billing_state
    billing_zip
    billing_country
    billing_tel
    billing_email
    merchant_param1
    merchant_param2
    merchant_param3
    merchant_param4
    merchant_param5
    merchant_param6
    mer_amount
    eci_value
    card_holder_name
    bank_receipt_no
    bank_qsi_no
    updatedSimphony
    updatedDBOrder
    errorMessage
    updatedSosocialWallet
    retryExecutionArn
    refunded
    refundedAmount
    percentageArray
    soSocialConversion
    earned
    isRegistered
    deleted
    CheckNum
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePaymentSubscriptionVariables,
  APITypes.OnUpdatePaymentSubscription
>;
export const onDeletePayment = /* GraphQL */ `subscription OnDeletePayment($filter: ModelSubscriptionPaymentFilterInput) {
  onDeletePayment(filter: $filter) {
    id
    accountID
    conceptID
    orderID
    tableID
    tableName
    channel
    tableOpenTime
    usedCash
    billAmount
    tipAmount
    serviceFees
    order_id
    tracking_id
    bank_ref_no
    order_status
    failure_message
    payment_mode
    card_name
    status_code
    status_message
    currency
    Amount
    billing_name
    billing_address
    billing_city
    billing_state
    billing_zip
    billing_country
    billing_tel
    billing_email
    merchant_param1
    merchant_param2
    merchant_param3
    merchant_param4
    merchant_param5
    merchant_param6
    mer_amount
    eci_value
    card_holder_name
    bank_receipt_no
    bank_qsi_no
    updatedSimphony
    updatedDBOrder
    errorMessage
    updatedSosocialWallet
    retryExecutionArn
    refunded
    refundedAmount
    percentageArray
    soSocialConversion
    earned
    isRegistered
    deleted
    CheckNum
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePaymentSubscriptionVariables,
  APITypes.OnDeletePaymentSubscription
>;
export const onCreateReview = /* GraphQL */ `subscription OnCreateReview($filter: ModelSubscriptionReviewFilterInput) {
  onCreateReview(filter: $filter) {
    id
    accountID
    conceptID
    orderID
    paymentReceipt
    rating
    review
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReviewSubscriptionVariables,
  APITypes.OnCreateReviewSubscription
>;
export const onUpdateReview = /* GraphQL */ `subscription OnUpdateReview($filter: ModelSubscriptionReviewFilterInput) {
  onUpdateReview(filter: $filter) {
    id
    accountID
    conceptID
    orderID
    paymentReceipt
    rating
    review
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReviewSubscriptionVariables,
  APITypes.OnUpdateReviewSubscription
>;
export const onDeleteReview = /* GraphQL */ `subscription OnDeleteReview($filter: ModelSubscriptionReviewFilterInput) {
  onDeleteReview(filter: $filter) {
    id
    accountID
    conceptID
    orderID
    paymentReceipt
    rating
    review
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReviewSubscriptionVariables,
  APITypes.OnDeleteReviewSubscription
>;
export const onCreateUserIP = /* GraphQL */ `subscription OnCreateUserIP($filter: ModelSubscriptionUserIPFilterInput) {
  onCreateUserIP(filter: $filter) {
    id
    ip
    date
    times
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserIPSubscriptionVariables,
  APITypes.OnCreateUserIPSubscription
>;
export const onUpdateUserIP = /* GraphQL */ `subscription OnUpdateUserIP($filter: ModelSubscriptionUserIPFilterInput) {
  onUpdateUserIP(filter: $filter) {
    id
    ip
    date
    times
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserIPSubscriptionVariables,
  APITypes.OnUpdateUserIPSubscription
>;
export const onDeleteUserIP = /* GraphQL */ `subscription OnDeleteUserIP($filter: ModelSubscriptionUserIPFilterInput) {
  onDeleteUserIP(filter: $filter) {
    id
    ip
    date
    times
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserIPSubscriptionVariables,
  APITypes.OnDeleteUserIPSubscription
>;
export const onCreateMainCategory = /* GraphQL */ `subscription OnCreateMainCategory(
  $filter: ModelSubscriptionMainCategoryFilterInput
) {
  onCreateMainCategory(filter: $filter) {
    id
    conceptID
    name
    precedence
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMainCategorySubscriptionVariables,
  APITypes.OnCreateMainCategorySubscription
>;
export const onUpdateMainCategory = /* GraphQL */ `subscription OnUpdateMainCategory(
  $filter: ModelSubscriptionMainCategoryFilterInput
) {
  onUpdateMainCategory(filter: $filter) {
    id
    conceptID
    name
    precedence
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMainCategorySubscriptionVariables,
  APITypes.OnUpdateMainCategorySubscription
>;
export const onDeleteMainCategory = /* GraphQL */ `subscription OnDeleteMainCategory(
  $filter: ModelSubscriptionMainCategoryFilterInput
) {
  onDeleteMainCategory(filter: $filter) {
    id
    conceptID
    name
    precedence
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMainCategorySubscriptionVariables,
  APITypes.OnDeleteMainCategorySubscription
>;
