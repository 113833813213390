import styled from "@emotion/styled";
import { Radio, RadioProps } from "@mui/material";

export default function MyRadio(props: RadioProps) {
  const BpIcon = styled("span")(() => ({
    borderRadius: "50%",
    width: 16,
    height: 16,
    border: "3px solid #707070",
    boxShadow: "unset",
    ".Mui-focusVisible &": {
      outline: "2px auto red",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "red",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#000",
    // "&::before": {
    //   display: "block",
    //   width: 16,
    //   height: 18,
    //   backgroundImage: "radial-gradient(#000,#000 28%,transparent 32%)",
    //   content: '""',
    // },
    "input:hover ~ &": {
      backgroundColor: "#000",
    },
  });

  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
