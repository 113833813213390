import {
  Backdrop,
  Box,
  Button,
  Grid,
  InputAdornment,
  Slide,
  TextField,
  Typography,
  createTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslationsEnum } from "../../constants/TranslationsEnum";
import lightThemeOptions from "../../theme/lightThemeOptions";
import { ChangeEvent, useState } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/ducks";
import {
  displayPrices,
  getEquivalentAED,
  isEqualToThirdDecimal,
  truncateToTwoDecimalsV2,
} from "../../helpers/utils";
import { IoIosCloseCircleOutline } from "react-icons/io";

const lightTheme = createTheme(lightThemeOptions);

type Props = {
  open: boolean;
  onClose: () => void;
  handleSubmit: (num: number) => void;
  max?: number;
  init?: number;
  isTip?: boolean;
};
function CustomTipModal({
  open,
  onClose,
  handleSubmit,
  max,
  init,
  isTip,
}: Props) {
  const { t } = useTranslation();
  const handleClose = () => {
    onClose();
  };
  const user = useSelector((state: StoreState) => state.app.user);
  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length > 10) {
      return;
    }
    if (max && Number(value) > Number(max)) {
      // console.log({ n: Number(value), max, s: (max ?? 0) - Number(value) });
      setCustomTip(value);
      setToSubmitValue(value);
      setError(true);
    } else if (Number(value) < 0) {
      setCustomTip(value);
      setToSubmitValue(value);
      setError(true);
    } else {
      setError(false);
      setCustomTip(value);
      if (max && Math.abs(max - Number(value)) < 0.001) {
        setToSubmitValue(max);
      } else {
        setToSubmitValue(value);
      }
    }
  };
  const [error, setError] = useState(false);
  const [customTip, setCustomTip] = useState<number | string>(init || "");
  const [toSubmitValue, setToSubmitValue] = useState<number | string>(
    init || ""
  );
  return (
    <>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 0,
          alignItems: "flex-end",
          backgroundColor: isTip ? "rgba(0, 0, 0, 0.5)" : "unset",
        }}
        onClick={(e) => handleClose()}
        open={open}
      >
        <Slide in={open} direction="up">
          <Box
            sx={{
              ...classes.containerBox,
              paddingBottom: isTip ? "0px" : "180px",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Grid container sx={classes.containerGrid}>
              <Grid
                item
                sx={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
                xs={12}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={classes.walletTitle}>
                    {" "}
                    {isTip
                      ? t(TranslationsEnum.payCustomAmount)
                      : t(TranslationsEnum.payCustomTip)}
                  </Typography>
                  {!isTip && (
                    <IoIosCloseCircleOutline
                      color="red"
                      onClick={handleClose}
                      size={"24px"}
                    />
                  )}
                </Box>
                {!isTip && (
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography sx={classes.walletSubtitle}>
                      max ({Number(max).toFixed(2)})
                    </Typography>
                  </Box>
                )}
                {isTip ? (
                  <TextField
                    placeholder="Ex: 100"
                    onChange={handleValueChange}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">AED</InputAdornment>
                      ),
                      inputProps: {
                        min: 0,
                      },
                    }}
                    value={customTip}
                    type={"number"}
                    sx={{
                      mt: "5px",
                      "& .MuiOutlinedInput-root": {
                        "& .MuiInputBase-input": {
                          padding: "10px",
                          px: "19px",
                          color: lightTheme.palette.secondary.main,
                          fontSize: "15px",
                          fontWeight: "medium",
                        },
                        "& fieldset": {
                          borderRadius: "32px",
                          borderColor: lightTheme.palette.secondary.main,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                      },
                    }}
                  />
                ) : (
                  <TextField
                    placeholder="Ex: 500"
                    onChange={handleValueChange}
                    fullWidth
                    InputProps={{
                      endAdornment: customTip ? (
                        <InputAdornment
                          position="start"
                          sx={{ fontSize: "9px", fontWeight: "bold" }}
                        >
                          (Equivalent to{" "}
                          {getEquivalentAED(
                            Number(customTip),
                            user?.soSocialConversion
                          ).toFixed(2)}{" "}
                          AED )
                        </InputAdornment>
                      ) : (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                      inputProps: {
                        min: 0,
                      },
                    }}
                    value={customTip}
                    type={"number"}
                    sx={{
                      mt: "5px",
                      "& .MuiOutlinedInput-root": {
                        "& .MuiInputBase-input": {
                          padding: "10px",
                          px: "19px",
                          color: lightTheme.palette.secondary.main,
                          fontSize: "12px",
                          fontWeight: "medium",
                        },
                        "& fieldset": {
                          borderRadius: "32px",
                          borderColor: lightTheme.palette.secondary.main,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                      },
                    }}
                  />
                )}
                <Typography
                  sx={{ px: "20px", height: "26px" }}
                  fontSize={12}
                  color={"error"}
                >
                  {error &&
                    (isTip
                      ? "Please enter a valid tip or 0"
                      : "Please Enter a valid amount.")}
                  {error && !isTip && (
                    <Typography
                      component={"span"}
                      fontSize={"10px"}
                      color={"error"}
                    >
                      (maximum social cash is {displayPrices(max!)})
                    </Typography>
                  )}
                </Typography>

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    sx={classes.payFull}
                    onClick={() => {
                      setCustomTip("");
                      setToSubmitValue("");
                      handleSubmit(
                        toSubmitValue === "" ? 0 : Number(toSubmitValue)
                      );
                    }}
                    disabled={error || !Boolean(customTip)}
                  >
                    Confirm
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Slide>
      </Backdrop>
    </>
  );
}
const classes = {
  containerBox: {
    boxSizing: "border-box",
    position: "relative",
    width: "100%",
    background: "#FFFFFF",
    padding: "35px",
    borderRadius: "50px 50px 0px 0px",
    maxWidth: "500px",
    boxShadow: "0px 0px 1px 0px",
  },
  containerGrid: {
    height: "100%",
    gap: "1rem",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
  },
  walletTitle: {
    fontWeight: "bold",
    fontSize: "15px",
    color: lightTheme.palette.secondary.main,
  },
  walletSubtitle: {
    fontWeight: "medium",
    fontSize: "12px",
    color: lightTheme.palette.secondary.main,
  },
  payFull: {
    backgroundColor: "black",
    textTransform: "unset",
    fontSize: "14px",
    fontWeight: "bold",
    borderRadius: "32px",
    width: "50%",
    mb: "5px",
    py: "10px",
    "&:hover": {
      backgroundColor: "black",
    },
  },
};
export default CustomTipModal;
