import { Box, Typography } from "@mui/material";
import { Html5QrcodeScanType, Html5QrcodeScanner } from "html5-qrcode";
import { useEffect, useRef } from "react";
import { getTableIDFromURL } from "../../helpers/utils";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";
import QRPlaceholder from "../../assets/common/qr.png";
import CapitalMotionLogo from "../../components/CapitalMotion/Logo";

const ScannerPage = () => {
  const navigate = useNavigate();
  const readerRef = useRef<HTMLDivElement>(null);
  const handleScan = (result: string) => {
    const tableId = getTableIDFromURL(result);
    navigate(`${tableId}`);
  };
  const handleError = (error: any) => {
    console.log(error);
  };

  let scanner: Html5QrcodeScanner;
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    scanner = new Html5QrcodeScanner(
      "reader",
      {
        fps: 10,
        qrbox: {
          width: 250,
          height: 250,
        },
        supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
        rememberLastUsedCamera: false,
        videoConstraints: {
          facingMode: "environment",
        },
      },
      false
    );
    scanner.render(handleScan, handleError);
    readerRef.current?.style.setProperty("border", "none", "important");
    return () => {
      scanner.clear();
    };
  }, []);
  return (
    <Box
      sx={{
        p: 2,
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          backgroundColor: "#f5f5f5",
          borderRadius: "8px",
          border: "1px solid #e0e0e0",
        }}
      >
        <Box>
          <CapitalMotionLogo
            color="primary"
            sx={{
              width: "300px",
              margin: "auto",
            }}
          />
        </Box>
        <Box
          className="qrScanBody"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 12,
          }}
        >
          <Box
            id="reader"
            ref={readerRef}
            sx={{
              position: "relative",
              width: "250px",
              height: "250px",
              backgroundImage: `url(${QRPlaceholder})`,
              backgroundBlendMode: "lighten",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
              borderRadius: "8px",
              overflow: "hidden",

              "& img": {
                display: "none",
              },
              "& #html5-qrcode-button-camera-permission": {
                bgcolor: "primary.main",
                color: "white",
                borderRadius: "8px",
                padding: "10px",
              },
            }}
          />
          <Typography
            sx={{
              mt: 2,
              fontWeight: "bold",
            }}
          >
            Please scan your table QR code
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ScannerPage;
