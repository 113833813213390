import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "../src/i18n/config";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import lightThemeOptions from "./theme/lightThemeOptions";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import store from "./store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Amplify } from "aws-amplify";
import amplifyconfig from "./amplifyconfiguration.json";
import ScrollTop from "./components/ScrollTop";

Amplify.configure(amplifyconfig);

let lightTheme = createTheme(lightThemeOptions);
lightTheme = responsiveFontSizes(lightTheme);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProvider theme={lightTheme}>
      <Router>
        <ScrollTop />
        <App />
      </Router>
      <ToastContainer limit={3} />
    </ThemeProvider>
  </Provider>
  // </React.StrictMode>
);

reportWebVitals();
