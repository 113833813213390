import ConceptOptions from "../../interfaces/ConceptOptions.interface";
import { Order } from "../../models/API";

// actions
const OPEN_SNACKBAR = "app/OPEN_SNACKBAR";
const CLOSE_SNACKBAR = "app/CLOSE_MODAL";
const PDF = "app/PDF";
const SET_CONCEPT_OPTIONS = "app/SET_CONCEPT_OPTIONS";
const SET_USER = "app/SET_USER";
const SET_TOKEN = "app/SET_TOKEN";
const SET_REGISTERED = "app/SET_REGISTERED";
const SET_PAYMENT_INFO = "app/SET_PAYMENT_INFO";
const SET_LOYALTY_MOOD = "app/SET_LOYALTY_MOOD";

export interface Wallet {
  amount: number;
}
export interface User {
  _id: string;
  first_name: string;
  last_name: string;
  wallet: Wallet;
  email: string;
  soSocialConversion: number;
  percentageArray: number;
}

export interface IAppState {
  mainSelected: any;
  conceptOptions: ConceptOptions | null;
  snackBarOpen: boolean;
  snackBarContent: string | null;
  snackBarSeverity: string;
  snackBarPersist: boolean;
  pdf: string | null;
  order: Order | null;
  user: User | null;
  token: string | null;
  registered: boolean;
  paymentInfo: any;
  loyaltyMood: "redeem" | "earn" | null;
}

const DEFAULT_STATE: IAppState = {
  mainSelected: null,
  conceptOptions: null,
  snackBarOpen: false,
  snackBarContent: null,
  snackBarSeverity: "info",
  snackBarPersist: false,
  pdf: null,
  order: null,
  user: null,
  token: null,
  registered: false,
  paymentInfo: null,
  loyaltyMood: null,
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return Object.assign({}, state, {
        snackBarOpen: true,
        snackBarContent: action.snackBarContent,
        snackBarSeverity: action.snackBarSeverity,
        snackBarPersist: action.persist ? action.persist : false,
      });

    case CLOSE_SNACKBAR:
      return Object.assign({}, state, {
        snackBarOpen: false,
        snackBarContent: null,
        snackBarSeverity: "info",
      });
    case PDF:
      return Object.assign({}, state, { pdf: action.pdf });
    case SET_CONCEPT_OPTIONS:
      return Object.assign({}, state, {
        conceptOptions: action.conceptOptions,
      });
    case SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case SET_REGISTERED:
      return {
        ...state,
        registered: action.state,
      };

    case SET_PAYMENT_INFO:
      return {
        ...state,
        paymentInfo: action.paymentInfo,
      };
    case SET_LOYALTY_MOOD:
      return {
        ...state,
        loyaltyMood: action.loyaltyMood,
      };
    default:
      return state;
  }
};

export default reducer;

// action creators
export const openSnackBar = (
  snackBarContent: string,
  snackBarSeverity: string,
  persist?: boolean
) => ({
  snackBarContent,
  snackBarSeverity,
  persist,
  type: OPEN_SNACKBAR,
});

export const closeSnackBar = () => ({
  snackBarContent: null,
  type: CLOSE_SNACKBAR,
});

export const setPDF = (pdf: string) => ({ pdf, type: PDF });

export const setConceptOptions = (conceptOptions: ConceptOptions) => ({
  conceptOptions,
  type: SET_CONCEPT_OPTIONS,
});

export const setUser = (user: User | undefined) => ({
  type: SET_USER,
  user,
});
export const setToken = (token: string | null) => ({
  type: SET_TOKEN,
  token,
});
export const setRegistered = (state: boolean) => ({
  type: SET_REGISTERED,
  state,
});

export const setPaymentInfo = (paymentInfo: any) => ({
  type: SET_PAYMENT_INFO,
  paymentInfo,
});
export const setLoyaltyMood = (loyaltyMood: "redeem" | "earn" | null) => ({
  type: SET_LOYALTY_MOOD,
  loyaltyMood,
});
