import "./Languages.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const languages = [
  { value: "English", label: "English", shortLabel: "En" },
  { value: "Arabic", label: "Arabic", shortLabel: "Ar" },
];

function Languages({ type, currentTarget }: any) {
  const { t, i18n } = useTranslation();
  const [isRTL, setIsRTL] = useState(false);
  const [anchorEl, setAnchorEl] = useState(currentTarget);
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleChange = (language: string) => {
    localStorage.setItem("language", language);

    if (language === "Arabic" || language === "he") {
      setIsRTL(true);
    } else {
      setIsRTL(false);
    }
    i18n.changeLanguage(language);
    if (language === "Arabic" || language === "he") {
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      document.documentElement.setAttribute("dir", "ltr");
    }
    handleClose();
  };
  
  return (
    <>
      {type && (
        <>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {languages.map((lang) => (
              <MenuItem
                key={lang.value}
                onClick={() => handleChange(lang.value)}
              >
                {t(lang.label)}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </>
  );
}

export default Languages;
