import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
  createTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import { TranslationsEnum } from "../../constants/TranslationsEnum";
import { IoIosCloseCircleOutline, IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/ducks";
import { handleSendOTP, handleVerifyOTP } from "../../services/otp";
import { toast } from "react-toastify";
import lightThemeOptions from "../../theme/lightThemeOptions";
import { setToken, setUser } from "../../store/ducks/app";
import { handleGetUserData } from "../../services/token";
const lightTheme = createTheme(lightThemeOptions);
type Props = {
  open: boolean;
  onClose: () => void;
  handleTimeLeftReset: () => void;
  secondsToWait: number;
  handleSuccessfulOTP: () => void;
  //   phoneNumber: string;
};
function OTPModal({
  open,
  secondsToWait,
  handleTimeLeftReset,
  //   checkCode,
  handleSuccessfulOTP,
  //   phoneNumber,
  onClose,
}: Props) {
  const { t, i18n } = useTranslation();
  const [otp, setOtp] = useState("");
  const token = useSelector((state: StoreState) => state.app.token);
  const conceptOptions = useSelector(
    (state: StoreState) => state.app.conceptOptions
  );
  const handleCloseModal = () => {
    onClose?.();
  };
  const handleOTP = async () => {};
  const dispatch = useDispatch();
  const handleClose = async () => {
    handleCloseModal();
    dispatch(setToken(null));
    setOtp("");
  };
  const [loading, setLoading] = useState<boolean>(false);
  const phone = useSelector((state: StoreState) => state.order.phoneNumber);
  const handleResend = async () => {
    try {
      setLoading(true);
      const response = await handleSendOTP(phone);
      if (response.result === "Failure") {
        toast.error(t(TranslationsEnum.otpResendError));
      }
      handleTimeLeftReset();
      setLoading(false);
    } catch (error) {
      toast.error(t(TranslationsEnum.otpResendError));
    }
  };

  const handleOtpChange = (otp: string) => {
    setOtp(otp);
  };
  const [otpError, setOTPError] = useState<boolean>(false);
  const verifyOTP = async (token: string | null, otp: string) => {
    setLoading(true);
    try {
      const response = await handleVerifyOTP(token, otp);
      if (response.result === "Failure") {
        setOTPError(true);
        toast.error("Error validating your otp.");
      } else if (response.token) {
        dispatch(setToken(response.token));
        const response_1 = await handleGetUserData(
          response.token,
          conceptOptions?.pos_id!
        );
        if (response_1.message === "unauthorized") {
          toast.error("Error retrieving your data.");
        } else {
          dispatch(setUser(response_1.data));
        }
        handleSuccessfulOTP();
        setOtp("");
        setOTPError(false);
      }
    } catch (error) {
      toast.error("error verifying OTP");
      console.log("error verifying OTP" + error);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (otp.length === 4) {
      verifyOTP(token, otp);
    }
  }, [otp]);
  return (
    <>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 0,
          alignItems: "flex-start",
        }}
        open={open}
      >
        <Box sx={classes.containerBox}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <>
              {!token && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={handleClose}
                ></Box>
              )}
              <Grid container sx={classes.containerGrid}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={classes.modalTitle}>
                      {t(TranslationsEnum.otpModalTitle)}
                    </Typography>
                    <IoIosCloseCircleOutline
                      color="red"
                      onClick={handleClose}
                      size={"24px"}
                    />
                  </Box>
                </Grid>
                <OtpInput
                  value={otp}
                  onChange={handleOtpChange}
                  numInputs={4}
                  renderSeparator={<Box width={".5rem"}> </Box>}
                  renderInput={(props: any) => <input {...props} />}
                  inputStyle={classes.inputStyle}
                  inputType="number"
                />
                {otpError && (
                  <Typography
                    sx={{ fontSize: "14px", color: "red", weight: "200" }}
                  >
                    {t(TranslationsEnum.otpVerifyError)}
                  </Typography>
                )}
              </Grid>
              <Box style={{ marginTop: "10px", textAlign: "center" }}>
                {secondsToWait ? (
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: 14,
                      fontWeight: "200",
                      wordWrap: "break-word",
                    }}
                  >
                    {t(TranslationsEnum.otpResendTime, { secondsToWait })}
                  </Typography>
                ) : (
                  <>
                    <span
                      style={{
                        color: "black",
                        fontSize: 12,
                        fontWeight: "medium",
                        wordWrap: "break-word",
                      }}
                    >
                      {t(TranslationsEnum.otpResendText)}
                    </span>
                    <span
                      onClick={() => {
                        handleResend();
                      }}
                      style={classes.OTPResendLink}
                    >
                      {t(TranslationsEnum.otpResendLink)}
                    </span>
                  </>
                )}
              </Box>
            </>
          )}
        </Box>
      </Backdrop>
    </>
  );
}
const classes = {
  containerBox: {
    boxSizing: "border-box",
    position: "relative",
    top: "50%",
    width: "80%",
    transform: "translateY(-50%)",
    background: "white",
    padding: {
      xs: "1.5rem",
      sm: "1rem",
      md: "1.5rem",
    },
    borderRadius: "10px",
    maxWidth: "500px",
  },
  containerGrid: {
    height: "100%",
    gap: "1rem",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
  },
  inputStyle: {
    background: "white",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.10)",
    borderRadius: 10,
    border: "1px solid " + lightTheme.palette.primary.main,
    color: lightTheme.palette.secondary.main,
    fontSize: "15px",
    padding: "10px 12px",
    fontWeight: "bold",
    // "&:focusVisible": {
    //   backgroundColor: "gray",
    //   outline: "yellow auto 1px",
    // },
  },
  modalTitle: {
    color: lightTheme.palette.secondary.main,
    fontWeight: "bold",
    fontSize: "15px",
  },
  OTPResendLink: {
    color: "black",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
  },
};
export default OTPModal;
