import { Box, CircularProgress, Grid } from "@mui/material";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/ducks";

const LoadingScreen = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.defaultMuted = true;
      // videoRef.current.play();
    }
  }, []);

  return (
    <Grid
      item
      container
      sm={12}
      justifyContent="center"
      alignItems="center"
      flexDirection={"column"}
      sx={{
        height: "100vh",
        background: "white",
        position: "absolute",
        zIndex: 9999,
        gap: "20px",
      }}
    >
      <Box sx={{ width: "80%" }}>
        <Box
          sx={{ width: "100%" }}
          component={"img"}
          src="https://qrsolutionbucket130835-production.s3.us-east-2.amazonaws.com/public/logo.webp"
        />
      </Box>
      <CircularProgress sx={{ color: "#11a9d5" }} size={48} />
    </Grid>
  );
};

export default LoadingScreen;
