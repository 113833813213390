import { Box, Grid, Link, Typography } from "@mui/material";
import React from "react";
import Logo from "../../components/Logo";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
const classes = {
  Header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
  },
  backButton: {
    cursor: "pointer",
    position: "absolute",
    left: "25px",
    color: (theme: any) => theme.palette.primary.main,
  },
};
export default function Terms() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        minHeight: "95svh",
        pt: 4,
      }}
    >
      <Grid item sx={classes.Header}>
        <ArrowBackIosNewIcon
          sx={classes.backButton}
          onClick={() => navigate(-1)}
        />
        <Box
          sx={{
            backgroundColor: "white",
            width: "130px",
            height: "130px",
            borderRadius: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "0px",
            filter: "drop-shadow(0px 3px 6px #00000029)",
            overflow: "hidden",
          }}
        >
          <Logo
            color="primary"
            sx={{
              cursor: "pointer",
              width: "100%",
            }}
          />
        </Box>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          p: 3,
          fontSize: "14px",
          color: "#333",
          fontWeight: "medium",
          "& li, & p": {
            fontSize: "14px",
            fontWeight: "medium",
            color: "#333",
          },
        }}
      >
        <Typography
          component={"h5"}
          fontSize={24}
          color={"#333"}
          fontWeight={"bold"}
        >
          Terms & Conditions
        </Typography>
        <Typography
          component={"span"}
          fontSize={12}
          color={"#333"}
          fontWeight={"bold"}
        >
          Last updated: 3rd of October 2024
        </Typography>
        <Typography
          fontSize={14}
          color={"#333"}
          fontWeight={"medium"}
          marginTop={1}
        >
          Welcome to SoSocial, a mobile application by Capital Motion
          Restaurants Management ("Company", "we", "our", "us"). The present
          terms and conditions (T&C) are posted on the website www.SoSocial.com
          (Website) and SoSocial application (Application), which are owned by
          Capital Motion Restaurants Management – Sole Proprietorship LLC
          (Company).
        </Typography>
        <Typography
          fontSize={14}
          color={"#333"}
          fontWeight={"medium"}
          marginTop={1}
        >
          ​ The T&C are intended to govern your utilization of the Website and
          the App and any of the Services provided by the Company to you through
          the Website and/or the Application. The Company may elect to change
          the T&C from time to time without any prior notice. The change will be
          implemented by posting amended T&C on the Website and the Application.
          If you use the Website and/or the Application or avail from any of the
          Services through the Website and/or the Application, You are deemed to
          have read and understood the T&C. You have also fully and
          unconditionally consented to be bound by the version of the T&C posted
          on the Website and the Application at the date and time of your use.
          Notwithstanding, if a new law or an amendment to an existing law
          requires a change of the T&C after You have availed from a Service
          through the Website and/or the Application but before such Service has
          been fulfilled, the Company may have to apply that new/amended law to
          Your concerned Service. You are also deemed to have fully, irrevocably
          and unconditionally consented to be bound by any change to the T&C.
        </Typography>
        <Typography
          fontSize={14}
          color={"#333"}
          fontWeight={"medium"}
          marginTop={1}
        >
          1. DEFINITIONS AND INTERPRETATIONS
          <br /> 1.1 ​Interpretation
          <br />
          Capitalized words shall have the meanings ascribed to them hereunder.
          The following definitions shall have the same meaning regardless of
          whether they appear in singular or in plural.
        </Typography>
        <Typography marginTop={1}>
          1.2 Definitions For the purpose of these T&C:
          <ul>
            <li>
              Account means a unique account created for an Account holder to
              access the Services or parts of the Services.
            </li>{" "}
            <li>
              Account holder (referred to in these T&C as either “Account
              holder”, “You”, Your”) means any person who holds an Account, as
              approved by the Company.
            </li>{" "}
            <li>
              Application refers to the SoSocial application developed and owned
              by the Company which is available on the Apple store and Android.
            </li>{" "}
            <li>
              Company (referred to in these T&C as either "the Company", "We",
              "Us" or "Our")means Capital Motion Restaurants Management – Sole
              Proprietorship LLC .
            </li>{" "}
            <li>Country means United Arab Emirates.</li>
            <li>
              Service(s) refers to the innovative social interaction services,
              networking services, booking and reservation services and payment
              which may be provided to an Account holder through the Application
              or the Website or both via the SoSocialPayment Processor.
            </li>
            <li>
              SoSocial Partner(s) means any participating restaurant to the
              SoSocial Programme and providing SoSocial Cashback to an Account
              holder.
            </li>{" "}
            <li>
              SoSocial Cash means the cash used by an Account holder to avail
              from the Services which may be topped up to an Account via Apple
              Pay, Samsung Pay through the App as well as by credit or debit
              cards via the SoSocial Payment Processor.
            </li>{" "}
            <li>
              SoSocial Cashback means the SoSocial Cash that an Account holder
              may earn back in accordance with the terms of the SoSocial
              Programme.
            </li>
            <li>
              SoSocial Programme means a programme developed and managed by the
              Company that Account holders are eligible to access while using
              the Services.
            </li>{" "}
            <li>
              SoSocial Payment Processor means the third party payment processor
              through whom online payments may be processed on the Website
              and/or the Application.
            </li>{" "}
            <li>
              Website refers to the website developed and owned by the Company
              which is accessible from the following webpage
              https://www.sosocial.com/.
            </li>{" "}
            <li>
              You means the Account holder accessing or using the Service, or a
              legal entity on behalf of which such Account holder is accessing
              or using the Service, as applicable.
            </li>
          </ul>
        </Typography>
        <Typography gutterBottom>2. CREATING AN ACCOUNT</Typography>
        <Typography gutterBottom>
          2.1 In order to take advantage of the Services, You will be required
          to create an Account and register Your details. If You do so, it is
          Your ongoing responsibility to make sure that any personal and payment
          details you provide to the Company is valid, accurate and complete.
          You are also expected to keep Your account details and password
          confidential and secure. The Company adheres to a Privacy Policy but
          will not be responsible for any loss suffered through misuse of those
          details.
        </Typography>
        <Typography gutterBottom>
          2.2 If You have doubts about a misuse of Your Account, you must
          immediately notify the Company.
        </Typography>
        <Typography gutterBottom>
          2.3 Prior to creating an Account, You are liable to ensure that You
          are eligible to use our Services. You are prohibited from creating an
          Account or using our Services unless all of the following
          representations and warranties are true, and by using our Services,
          You represent and warrant that:
        </Typography>
        <ul>
          <li>
            You are legally qualified to enter into a binding contract with Us
            and to avail from the Services;
          </li>
          <li>You are not prohibited by law from using Our Services;</li>
          <li>
            You have not committed, been convicted of a felony, offense or any
            crime punishable under UAE laws.
          </li>
          <li>You do not have more than one account on our Services;</li>
          <li>You have not previously been removed from our Services by Us.</li>
        </ul>
        <Typography gutterBottom>
          2.4 If at any time, You cease to meet all these requirements, your
          Account will be automatically blocked and deleted. We retain the right
          to remove Your access to our Services without prior notice.
        </Typography>
        <Typography gutterBottom>2.5 Further, You agree to:</Typography>
        <ul>
          <li>
            Comply with these T&C and check this page at all times to ensure You
            are aware of any updates;
          </li>
          <li>Use the latest version of the Website and/or Application;</li>
          <li>
            Take adequate measures to protect the security of Your login
            information;
          </li>
          <li>
            Comply with all applicable laws and customs of the Country including
            without limitation data protection laws, intellectual property laws
            and criminal and penal laws.
          </li>
        </ul>
        <Typography gutterBottom>
          2.6 You also agree that You will refrain from:
        </Typography>
        <ul>
          <li>
            Misrepresenting Your identity, age, gender or any other personal
            information;
          </li>
          <li>
            Using our Services in a way to interfere with, disrupt or negatively
            affect the Application and/or the Website;
          </li>
          <li>Using the login information of another Account holder;</li>
          <li>Assigning or transferring the Account to another party;</li>
          <li>Using our Services for business or commercial purposes;</li>
          <li>
            Using our Services for any illegal purpose, including without
            limitation the posting or sharing of any prohibited material or
            content;
          </li>
          <li>
            Disclosing private or proprietary information that you do not have
            the right to disclose or infringing any intellectual property or
            data privacy laws;
          </li>
          <li>
            Harassing, intimidating, assaulting, defaming, harming or engaging
            in any form of abuse or psychological harm to other Account holders;
            or
          </li>
          <li>
            Uploading any viruses or compromising the security of the Website
            and/or the Application.
          </li>
        </ul>
        <Typography gutterBottom>
          2.7 The Company has the absolute and discretionary right to suspend or
          cancel any Account or reject the use of any Service by any Account
          holder who does not comply with the T&C.
        </Typography>
        <Typography gutterBottom>
          2.8 Further, the Company may at any time, and at its sole and absolute
          discretion amend or vary partially or entirely the Services and/or the
          SoSocial Programme.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3. PRIVACY POLICY
        </Typography>
        <Typography gutterBottom>
          Please refer to Our Privacy Policy posted on the Website and the
          Application for details on how we deal with Your data.
        </Typography>
        <Typography gutterBottom>4. SERVICES</Typography>
        <ul style={{ fontSize: "14px" }}>
          <li>
            Restaurant and or venue promotions, including but not limited to
            Food, Drinks, Tobacco &amp; Alcohol as per the offerings of the
            respective venues.
          </li>
          <li>Table booking</li>
          <li>QR Code payment processing services</li>
          <li>Invite to table</li>
          <li>Finding and adding buddies</li>
          <li>Chat with buddies</li>
          <li>SoSocial Cash redemption (loyalty program)</li>
          <li>SoSocial Cash gift/wallet</li>
          <li>SoSocial Cash top up</li>
          <li>City events and shows details</li>
          <li>Booking and buying tickets for events</li>
          <li>
            Socializing features (ability to view and interact with other
            Account holders dining at Our locations)
          </li>
        </ul>
        <Typography gutterBottom>5. SOSOCIAL PROGRAMME</Typography>
        <Typography gutterBottom>SoSocial Cash</Typography>
        <ul>
          <li>
            The SoSocial Cashback program allows customers to earn and redeem
            SoSocial cash at Our restaurants.
          </li>
          <li> SoSocial cash:</li>
          <ul>
            <li>
              Is transferable to Account holders but has no cash value and is
              not a currency.
            </li>
            <li>
              Can only be redeemed at participating locations subject to
              approval of the venues and their terms and conditions.
            </li>
            <li>
              Is subject to expiration dates as per the terms of the SoSocial
              Programme
            </li>
          </ul>
        </ul>
        <Typography gutterBottom>Promotions and Event Booking</Typography>
        <Typography gutterBottom>
          Promotions and events displayed in the App are subject to availability
          and may change without notice. We do not guarantee the accuracy or
          completeness of any promotion or event details.
        </Typography>
        <Typography gutterBottom>SOCIALIZING FEATURES</Typography>
        <Typography gutterBottom>
          The Application includes features allowing Account holders to view
          other Account holders dining at Our locations. By using this feature,
          you agree to interact with other Account holders respectfully and
          lawfully. The Company is not responsible for the interactions between
          Account holders and shall have the discretionary right to cancel or
          suspend any Account should the Account holder fail to comply with the
          terms of this Clause.
        </Typography>
        <Typography gutterBottom>6 SOSOCIAL CASHBACK</Typography>
        <Typography gutterBottom>
          6.1 The SoSocial Cashback program allows customers to earn and redeem
          SoSocial cash at Our restaurants. SoSocial Cash:
        </Typography>
        <ul>
          <li>
            Is transferable to Account holders but has no cash value and is not
            a currency.
          </li>
          <li>
            Can only be redeemed at participating locations subject to approval
            of the venues and their terms and conditions.
          </li>
          <li>
            Is subject to expiration dates as per the terms of the SoSocial
            Programme.
          </li>
          <li>
            SoSocial Cashback can be earned and redeemed with SoSocial Partners
            subject to any applicable terms and conditions required by the
            SoSocial Partner. It is Your responsibility to ensure that You
            comply with any related third-party terms and that You are aware of
            any privacy policies related third party that may apply to You, in
            conjunction with the present T&C.
          </li>
        </ul>
        <Typography gutterBottom>
          6.2 The Company may, at any time and without prior notice to Account
          holders, change or vary the terms and conditions for earning SoSocial
          Cashback. It is Your responsibility to verify the applicable terms for
          earning such SoSocial Cashback.
        </Typography>
        <Typography gutterBottom>
          6.3 The Company may, at its sole and absolute discretion, modify or
          restrict Your ability to earn or use SoSocial Cashback, immediately
          and without notice, where the Company reasonably believes that:
        </Typography>
        <ul>
          <li>You have not complied with any of these Terms;</li>
          <li>
            You have engaged in wilful misconduct, including but not limited to
            the fraudulent acquisition or use of the SoSocial Cashback; or
          </li>
          <li>
            If any of the representations and warranties set out in Clause 2
            prove to be false, incomplete or misleading.
          </li>
        </ul>
        <Typography gutterBottom>
          6.4 In the event of cancellation of Your Account, You will lose any
          right related to you SoSocial Cash.
        </Typography>
        <Typography gutterBottom>7 SOSOCIAL PAYMENT PROCESSOR</Typography>
        <Typography gutterBottom>
          7.1 We currently accept payments for Services on our Website or
          Application through our SoSocial Payment Processor.
        </Typography>
        <Typography gutterBottom>
          7.2 By entering payment details onto our Website and/or Application,
          you warrant that you are duly authorized to pay using those details.
        </Typography>
        <Typography gutterBottom>
          7.3 SoSocial operates on a non-refundable basis.
        </Typography>
        <Typography gutterBottom>8 INTELLECTUAL PROPERTY RIGHTS</Typography>
        <Typography gutterBottom>
          8.1 Your use of the Website and/or the Application and its contents
          does not grant You any copyright, design, trademark or other
          intellectual property rights relating to any graphics, photographs,
          including all image rights, sounds, music, video, audio, layout or
          text, including our software, HTML or other code contained in the
          Website and/or the Application.
        </Typography>
        <Typography gutterBottom>
          8.2 All such content including third party trademarks, designs, and
          related intellectual property rights mentioned or displayed on the
          Website and/or the Application is typically protected by national and
          international intellectual property laws and treaties. You are
          permitted to use the content only as expressly authorized by Us. Any
          reproduction or redistribution of the Website or the Application’s
          content is prohibited and may result in civil and criminal penalties.
        </Typography>
        <Typography gutterBottom>
          8.3 Without limiting the foregoing, linking, commercially exploiting,
          copying and use of the above listed materials on any other server,
          location or support for publication, reproduction or distribution is
          expressly prohibited. However, You are permitted to make one copy for
          the purposes of viewing the content for your own personal use. No
          right, title or interest in any downloaded content is transferred to
          You as a result of any such downloading or copying.
        </Typography>
        <Typography gutterBottom>9 THIRD PARTY SITES</Typography>
        <Typography gutterBottom>
          9.1 Our Website and Application may include hyperlinks to other
          websites or resources operated by third parties, including our
          SoSocial Partners. We are not responsible for the content or accuracy
          of any information/data that is posted on third parties’ websites.
        </Typography>
        <Typography gutterBottom>
          9.2 We are also not liable, directly or indirectly, for the privacy
          policies of such websites or applications, including (without
          limitation) any advertising, products or other materials or services
          on or available from such websites or resources, nor for any damage,
          loss or offense caused by, or in connection with, the use of or
          reliance on any such content, items or services available on such
          third parties’ websites or resources.
        </Typography>
        <Typography gutterBottom>10 NO WARRANTY</Typography>
        <Typography gutterBottom>
          10.1 Except as otherwise provided in these T&C or as required in the
          applicable laws, We make no representation, warranty or undertaking
          and covenants no other conditions, express or implied, regarding any
          matter, including, without limitation, the suitability, fitness for a
          specific use or purpose of any Service provided through the Website
          and the Application.
        </Typography>
        <Typography gutterBottom>
          10.2 Your use of the Website and/or the Application is made at Your
          sole risk.
        </Typography>
        <Typography gutterBottom>
          10.3 Save as required by law, We make no warranties or undertakings
          that:
        </Typography>
        <ul>
          <li>
            <Typography gutterBottom>
              the access to the Website and/or the Application will be
              uninterrupted or error-free;
            </Typography>
          </li>
          <li>
            <Typography gutterBottom>
              the Website and/or the Application will be secure;
            </Typography>
          </li>
          <li>
            <Typography gutterBottom>
              the Website and/or the Application or the server that makes the
              Website/Application available will be virus-free; or
            </Typography>
          </li>
          <li>
            <Typography gutterBottom>
              the information on the Website and/or the Application will be
              correct, accurate, adequate, useful, timely, reliable or otherwise
              complete.
            </Typography>
          </li>
        </ul>
        <Typography gutterBottom>
          10.4 Any download of content from the Website is done at the Account
          holder’s sole risk. You will be exclusively responsible for any damage
          to your computer/device or loss of data that results from the download
          of any such content.
        </Typography>
        <Typography gutterBottom>11 LIABILITY</Typography>
        <Typography gutterBottom>
          11.1 Save for death or personal injury arising out of our negligence
          or as required by law, under no circumstances shall We, Our
          affiliates, employees, directors, officers, agents or suppliers be
          liable to You or to any other person for any indirect, special,
          incidental or consequential losses or damages of any nature arising
          out of or in connection with the Services and/or the use of the
          Website and/or the Application or the use or inability to use the
          Website, including, without limitation, damages for loss of profits,
          loss of goodwill or loss of data. Without prejudice to the foregoing,
          in no event, We will be liable for any amounts in excess of the amount
          paid by you for the item(s) in respect of which the claim arose.
        </Typography>
        <Typography gutterBottom>
          11.2 You will be responsible to the Company for any claims, costs,
          damages, losses, liabilities, expenses or legal proceedings brought
          against the Company by any third party as a result of unlawful or
          unauthorized access to the Services, the Website, the Application or
          your Account in breach of these T&C.
        </Typography>
        <Typography gutterBottom>
          11.3 Nothing in these T&C is intended to impact any rights You or We
          may have under any applicable law.
        </Typography>
        <Typography gutterBottom>12 INDEMNITY</Typography>
        <Typography gutterBottom>
          12.1 You agree to defend, indemnify and hold us, our directors,
          agents, affiliates, licensors and suppliers harmless from and against
          any and all claims, damages, costs and expenses, including legal fees,
          arising from or related to your use of the Website and/or your breach
          of any representation, warranty, or other caused by your action or
          inaction.
        </Typography>
        <Typography gutterBottom>13 GENERAL PROVISIONS</Typography>
        <Typography gutterBottom>
          13.1 You acknowledge and agree that these T&C constitute the complete
          and exclusive terms and conditions between us concerning your use of
          the Website and any purchase by you of any item through the Website.
        </Typography>
        <Typography gutterBottom>
          13.2 Any failure to exercise or any delay by the Company in exercising
          its rights or remedies under these T&C shall not be construed as a
          waiver thereof. The rights and remedies provided by these T&C are
          cumulative and are not exclusive of any rights or remedies provided by
          law.
        </Typography>
        <Typography gutterBottom>
          13.3 If any term of these T&C is held to be illegal or unenforceable,
          the remaining terms - or parts thereof - will not be affected and will
          remain in full force and effect.
        </Typography>
        <Typography gutterBottom>
          13.4 If You experience any problems with access or use of your Account
          or if have any questions in relation to the Services or the SoSocial
          Programme, please contact us at support@SoSocial.com
        </Typography>
        <Typography gutterBottom>
          14 GOVERNING LAW AND DISPUTE RESOLUTION
        </Typography>
        <Typography gutterBottom>
          These T&C are governed by the Federal laws of the United Arab Emirates
          and all disputes arising hereunder are subject to the exclusive
          jurisdiction of the Abu Dhabi courts.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          p: 3,
          fontSize: "14px",
          color: "#333",
          fontWeight: "medium",
          "& li, & p": {
            fontSize: "14px",
            fontWeight: "medium",
            color: "#333",
          },
        }}
      >
        <Typography
          component={"h5"}
          fontSize={24}
          color={"#333"}
          fontWeight={"bold"}
        >
          Privacy Policy
        </Typography>
        <Typography
          component={"span"}
          fontSize={12}
          color={"#333"}
          fontWeight={"bold"}
        >
          Last updated: 3rd of October 2024
        </Typography>
        <Typography variant="body1" gutterBottom>
          SoSocial is committed to safeguarding the privacy and confidentiality
          of Personal Information. This Privacy Policy (Policy) describes our
          policies and procedures on the collection, use and disclosure of Your
          Personal Information when You use the Service and tells You about Your
          privacy rights and how the law protects You in this respect. We use
          Your Personal Information to provide and improve the Service. By using
          the Service, You agree to the collection and use of Personal
          Information in accordance with this Policy.
        </Typography>
        <Typography variant="body1" gutterBottom>
          INTERPRETATION AND DEFINITIONS
        </Typography>
        <Typography variant="body1" gutterBottom>
          Capitalized words shall have the meanings ascribed to them hereunder.
          The following definitions shall have the same meaning regardless of
          whether they appear in singular or in plural.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Definitions
        </Typography>
        <Typography variant="body1" gutterBottom>
          <ul>
            <li>
              Account means a unique account created for an Account holder to
              access the Services or parts of the Services.
            </li>
            <li>
              Affiliate means an entity that controls, is controlled by or is
              under common control with a party, where "control" means ownership
              of 50% or more of the shares, equity interest or other securities
              entitled to vote for election of directors or other managing
              authority.
            </li>
            <li>
              Application refers to SoSocial application development, the
              software program provided by the Company which is available on App
              Store and Android.
            </li>
            <li>
              Company (referred to as either "the Company", "We", "Us" or "Our"
              in this Agreement) refers to Capital Motion Restaurants Management
              – Sole Proprietorship LLC
            </li>
            <li>
              Cookies means the small files that are placed on Your computer,
              mobile device or any other device by a website, containing the
              details of Your browsing history.
            </li>
            <li>Country refers to United Arab Emirates</li>
            <li>
              Device means any device that can access the Service such as a
              computer, a smartphone or a digital tablet.
            </li>
            <li>
              Personal Information is any information that relates to an
              identified or identifiable individual.
            </li>
            <li>
              Service(s) refers to the innovative social interaction services,
              networking services, booking and reservation services and payment
              which may be provided to an Account holder through the
              Application, the Website or both.
            </li>
            <li>
              Service Provider means any natural or legal person who processes
              the data on behalf of Us. It refers to third-party companies or
              individuals employed by Us to facilitate the Service, to provide
              the Service on Our behalf, to perform supporting services related
              to the Service or to assist Us in analyzing how the Service is
              used.
            </li>
            <li>
              Usage Data refers to data collected automatically, either
              generated by the use of the Service or from the Service
              infrastructure itself (for example, the duration of a page visit).
            </li>
            <li>
              Website refers to the website which is accessible from
              https://www.sosocial.com/
            </li>
            <li>
              You means the individual accessing or using the Service, or a
              legal entity on behalf of which such individual is accessing or
              using the Service, as applicable.
            </li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom>
          COLLECTING AND USING YOUR PERSONAL INFORMATION
        </Typography>
        <Typography variant="body1" gutterBottom>
          Types of Data Collected
        </Typography>
        <Typography variant="body1" gutterBottom>
          Personal Information
        </Typography>
        <Typography variant="body1" gutterBottom>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <ul>
            <li>Email address</li>
            <li>First name and last name;</li>
            <li>Phone number;</li>
            <li>Identification number;</li>
            <li>Address, Emirate, Sector, Street, Postal Code;</li>
            <li>Cookies and Usage Data</li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom>
          USAGE DATA
        </Typography>
        <Typography variant="body1" gutterBottom>
          Usage Data is collected automatically when using the Service.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </Typography>
        <Typography variant="body1" gutterBottom>
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device's unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </Typography>
        <Typography variant="body1" gutterBottom>
          TRACKING TECHNOLOGIES AND COOKIES
        </Typography>
        <Typography variant="body1" gutterBottom>
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information.
        </Typography>
        <Typography variant="body1" gutterBottom>
          The technologies We use may include:
        </Typography>
        <ul>
          <li>
            Cookies or Browser Cookies. A cookie is a small file placed on Your
            Device. You can instruct Your browser to refuse all Cookies or to
            indicate when a Cookie is being sent. However, if You do not accept
            Cookies, You may not be able to use some parts of our Service.
            Unless you have adjusted Your browser setting so that it will refuse
            Cookies, our Service may use Cookies.
          </li>
          <li>
            Web Beacons. Certain sections of our Service and our emails may
            contain small electronic files known as web beacons (also referred
            to as clear gifs, pixel tags, and single-pixel gifs) that permit
            IDC, for example, to count users who have visited those pages or
            opened an email and for other related website statistics (for
            example, recording the popularity of a certain section and verifying
            system and server integrity).
          </li>
        </ul>
        <Typography variant="body1" gutterBottom>
          Cookies can be Persistent or Session Cookies. Persistent Cookies
          remain on Your personal computer or mobile device when You go offline,
          while Session Cookies are deleted as soon as You close Your web
          browser. We use both Session and Persistent Cookies for the purposes
          set out below:
        </Typography>
        <Typography variant="body1" gutterBottom>
          Necessary / Essential Cookies
        </Typography>
        <Typography variant="body1" gutterBottom>
          Type: Session Cookies
        </Typography>
        <Typography variant="body1" gutterBottom>
          Administered by: Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          Purpose: These Cookies are essential to provide You with services
          available through the Website and to enable You to use some of its
          features. They help to authenticate users and prevent fraudulent use
          of user accounts. Without these Cookies, the services that You have
          asked for cannot be provided, and We only use these Cookies to provide
          You with those services.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Cookies Policy / Notice Acceptance Cookies
        </Typography>
        <Typography variant="body1" gutterBottom>
          Type: Persistent Cookies
        </Typography>
        <Typography variant="body1" gutterBottom>
          Administered by: Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          Purpose: These Cookies identify if users have accepted the use of
          cookies on the Website.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Functionality Cookies
        </Typography>
        <Typography variant="body1" gutterBottom>
          Type: Persistent Cookies
        </Typography>
        <Typography variant="body1" gutterBottom>
          Administered by: Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          Purpose: These Cookies allow us to remember choices You make when You
          use the Services, such as remembering your login details or language
          preference. The purpose of these Cookies is to provide You with a more
          personal experience and to avoid You having to re-enter your
          preferences every time You use the Website or the Application.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Optional functionalities
        </Typography>
        <Typography variant="body1" gutterBottom>
          The app includes optional functionalities which the user may elect to
          use to benefit from additional features, and these require allowing
          access to information including but not limited to: contact lists, app
          online status and geolocation which are necessary to allow the app to
          perform the optional functions including but not limited to revealing
          your location and or app online status to other users. These optional
          functionalities can be controlled to a certain extent from within the
          app.
        </Typography>
        <Typography variant="body1" gutterBottom>
          USE OF YOUR PERSONAL INFORMATION
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may use Personal Information for the following purposes:
        </Typography>
        <ul>
          <li>
            To provide and maintain our Services, including monitoring the usage
            of our Service.
          </li>
          <li>To detect, prevent and address technical issues,</li>
          <li>
            To manage Your Account: to manage Your registration as a user of the
            Service. The Personal Information You provide can give You access to
            different functionalities of the Service that are available to You
            as a registered user.
          </li>
          <li>
            For the performance of a contract: such as the development,
            compliance and undertaking of a purchase contract for products,
            items or services You have purchased or of any other contract
            concluded through the Service.
          </li>
          <li>
            To contact You: To contact You by email, telephone calls, SMS, or
            other equivalent forms of electronic communication, such as a mobile
            application's push notifications regarding updates or informative
            communications related to the functionalities, products or
            contracted services, including the security updates, when necessary
            or reasonable for their implementation.
          </li>
          <li>
            To provide You with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless You have opted not to receive such information.
          </li>
          <li>
            To manage Your requests: To attend and manage Your requests to Us.
          </li>
          <li>
            For business transfers: We may use Your information to evaluate or
            conduct a merger, divestiture, restructuring, reorganization,
            dissolution, or other sale or transfer of some or all of Our assets,
            whether as a going concern or as part of bankruptcy, liquidation, or
            similar proceeding, in which Personal Information held by Us about
            Service users is among the assets transferred.
          </li>
          <li>
            For other purposes: We may use Your information for other purposes,
            such as data analysis, identifying usage trends, determining the
            effectiveness of our promotional campaigns and evaluating and
            improving our Service, products, services, marketing and your
            experience while using the Service.
          </li>
        </ul>
        <Typography variant="body1" gutterBottom>
          We may share Your Personal Data in the following situations:
        </Typography>
        <ul>
          <li>
            With Service Providers: We may share Your personal information with
            Service Providers to monitor and analyze the use of our Service, to
            contact You.
          </li>
          <li>
            For business transfers: We may share or transfer Your personal
            information in connection with, or during negotiations of, any
            merger, sale of Company assets, financing, or acquisition of all or
            a portion of Our business to another company.
          </li>
          <li>
            With Affiliates: We may share Your information with Our affiliates,
            in which case we will require those affiliates to honor this Privacy
            Policy. Affiliates include Our parent company and any other
            subsidiaries, joint venture partners or other companies that We
            control.
          </li>
          <li>
            With business partners: We may share Your information with Our
            business partners to offer You certain products, services or
            promotions.
          </li>
          <li>
            With other users: When you share personal information or otherwise
            interact in public areas with other users, such information may be
            viewed by all users and may be publicly distributed outside.
          </li>
          <li>
            With Your consent: We may disclose Your personal information for any
            other purpose with Your consent.
          </li>
        </ul>
        <Typography variant="body1" gutterBottom>
          RETENTION OF YOUR PERSONAL INFORMATION
        </Typography>
        <Typography variant="body1" gutterBottom>
          We will retain Your Personal Information only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Information to the extent necessary to
          comply with our legal obligations (for example, if we are required to
          retain your data to comply with applicable laws or regulatory
          obligations), resolve disputes, and enforce our legal agreements and
          policies.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We will also retain Usage Data for internal analysis purposes. Usage
          Data is generally retained for a shorter period of time, except when
          this data is used to strengthen the security or to improve the
          functionality of Our Service, or We are legally obligated to retain
          this data for longer time periods in accordance with legal or
          regulatory requirements.
        </Typography>
        <Typography variant="body1" gutterBottom>
          TRANSFER OF YOUR PERSONAL INFORMATION
        </Typography>
        <Typography variant="body1" gutterBottom>
          Your information, including Personal Data, is processed at Our
          operating offices and in any other places where the parties involved
          in the processing are located. It means that this information may be
          transferred to — and maintained on — computers located outside of Your
          state, emirate, country or other governmental jurisdiction where the
          data protection laws may differ than those from Your jurisdiction.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your explicit consent to that transfer.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We will take all steps reasonably necessary to ensure that Your data
          is treated securely and in accordance with this Privacy Policy and no
          transfer of Your Personal Information will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your Personal Infromation and other personal
          information.
        </Typography>
        <Typography variant="body1" gutterBottom>
          RIGHTS IN RELATION TO YOUR PERSONAL INFORMATION
        </Typography>
        <Typography variant="body1" gutterBottom>
          You have a number of rights under data protection legislation:
        </Typography>
        <ol>
          <li>
            You can request any information we keep in our records. Ask for it
            in writing by contacting us. You may be asked for proof of identity.
          </li>
          <li>
            You have the right to ask us to stop using or to restrict the
            processing of your Personal Information.
          </li>
          <li>
            You can withdraw your consent to us processing your Personal
            Information at any time (where such processing is based on your
            consent).
          </li>
          <li>
            If you believe our records are inaccurate you have the right to ask
            for those records concerning you to be updated. To update your
            records please contact us.
          </li>
          <li>
            You have the right to delete or request that We assist in deleting
            the Personal Information that We have collected about You.
          </li>
          <li>
            Our Service may give You the ability to delete certain information
            about You from within the Service. You may update, amend, or delete
            Your information at any time by signing in to Your Account, if you
            have one, and visiting the account settings section that allows you
            to manage Your Personal Information. You may also contact Us to
            request access to, correct, or delete any personal information that
            You have provided to Us.
          </li>
          <li>
            Please note, however, that We may need to retain certain information
            when we have a legal or regulatory obligation or lawful basis to do
            so.
          </li>
        </ol>
        <Typography variant="body1" gutterBottom>
          DISCLOSURE OF YOUR PERSONAL INFORMATION
        </Typography>
        <Typography variant="body1" gutterBottom>
          Business Transactions
        </Typography>
        <Typography variant="body1" gutterBottom>
          If We are involved in a merger, acquisition or asset sale, Your
          Personal Information may be transferred. We will provide you with a
          notice of such transfer before Your Personal Information is
          transferred and becomes subject to a different Privacy Policy.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Law enforcement
        </Typography>
        <Typography variant="body1" gutterBottom>
          Under certain circumstances, We may be required to disclose Your
          Personal Information if required to do so by law or in response to
          valid requests by public authorities (e.g. a court or a government
          agency).
        </Typography>
        <Typography variant="body1" gutterBottom>
          Other legal requirements
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may never disclose Your Personal Information without your explicit
          consent save for the following events:
        </Typography>
        <ul>
          <li>
            Comply with a legal obligation for example, in response to a court
            order or other legal obligations, or in response to a valid request
            from a regulatory authority;
          </li>
          <li>Protect and defend Our rights or property</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li>Protect against legal liability</li>
        </ul>
        <Typography variant="body1" gutterBottom>
          SECURITY OF YOUR PERSONAL INFORMATION
        </Typography>
        <Typography variant="body1" gutterBottom>
          The security of Your Personal Information is important to Us, but
          remember that no method of transmission over the Internet, or method
          of electronic storage is fully secure. While We strive to use
          commercially acceptable means to protect Your Personal Information, We
          cannot guarantee its absolute security. Transmission is at your own
          risk.
        </Typography>
        <Typography variant="body1" gutterBottom>
          CHILDREN'S PRIVACY
        </Typography>
        <Typography variant="body1" gutterBottom>
          Our Service does not address anyone under the age of 18. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 18. If You are a parent or guardian and you are aware
          that Your child has provided Us with Personal Information, please
          contact Us. If We become aware that We have collected Personal
          Information from anyone under the age of 18 without verification of
          parental consent, We take steps to remove that information from Our
          servers. If We need to rely on consent as a legal basis for processing
          Your information and Your country requires consent from a parent, We
          may require Your parent's consent before We collect and use that
          information.
        </Typography>
        <Typography variant="body1" gutterBottom>
          GDPR COMPLIANCE
        </Typography>
        <Typography variant="body1" gutterBottom>
          Data Subject Rights. You will have the right to access, rectify, or
          delete their personal data, as well as the right to data portability
          and to restrict processing. Requests can be made by contacting
          [dataprotection@sosocial.com].
        </Typography>
        <Typography variant="body1" gutterBottom>
          Lawful Basis for Processing
        </Typography>
        <Typography variant="body1" gutterBottom>
          We will process personal data based on consent, contractual necessity,
          and legitimate interests, ensuring compliance with GDPR requirements.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Data Protection Officer (DPO)
        </Typography>
        <Typography variant="body1" gutterBottom>
          We have appointed a Data Protection Officer (DPO). For any data
          protection concerns or inquiries, contact the DPO at
          [dataprotection@sosocial.com].
        </Typography>
        <Typography variant="body1" gutterBottom>
          BREACH NOTIFICATION
        </Typography>
        <Typography variant="body1" gutterBottom>
          In the event of a data breach, We will notify the relevant supervisory
          authority as soon as reasonably possible (and in any event in
          accordance with the applicable laws) of becoming aware of the breach,
          unless the breach is unlikely to result in a risk to the rights and
          freedoms of individuals. If the breach is likely to result in a high
          risk to the rights and freedoms of individuals, We will also inform
          the affected individuals without undue delay.
        </Typography>
        <Typography variant="body1" gutterBottom>
          CHANGES TO THIS PRIVACY POLICY
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page. We will
          let You know via a prominent notice on Our Service, prior to the
          change becoming effective and update the "Last updated" date at the
          top of this Privacy Policy. You are advised to review this Privacy
          Policy periodically for any changes. Changes to this Privacy Policy
          are effective when they are posted on this page.
        </Typography>
        <Typography variant="body1" gutterBottom>
          CONTACT US
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you have any questions about this Policy, You can contact us by
          visiting this page on our website:
          <Link href="https://www.SoSocial.com/contact-us/">
            https://www.SoSocial.com/contact-us/
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}
