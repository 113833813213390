import { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { CircularProgress } from "@mui/material";

// Configure the worker URL for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const CustomLoadingIndicator = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
    }}
  >
    <CircularProgress></CircularProgress>
  </div>
);

function PdfView(pdf: any) {
  const [numPages, setNumPages] = useState<null | number>(null);
  const [pageHeight, setPageHeight] = useState(window.innerWidth / 1.5); // Initial height, adjust as needed

  const selectedPDF = useSelector((state: any) => state.app.pdf);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    if (selectedPDF) {
      setNumPages(null);
    }
  }, [selectedPDF]);

  useEffect(() => {
    const handleResize = () => {
      setPageHeight(window.innerWidth / 1.5);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderPages = () => {
    const pages = [];
    if (numPages)
      for (let i = 1; i <= numPages; i++) {
        pages.push(
          <Page
            key={i}
            pageNumber={i}
            width={window.innerWidth}
            height={pageHeight}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        );
      }
    return pages;
  };

  return (
    <div>
      {selectedPDF && (
        <Document
          file={`https://qrsolutionbucket130835-production.s3.us-east-2.amazonaws.com/public/${selectedPDF}`}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<CustomLoadingIndicator />}
        >
          {renderPages()}
        </Document>
      )}
    </div>
  );
}

export default PdfView;
