import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import Logo from "../Logo";

interface NotFoundProps {
  type: "order" | "table" | "payment";
}
const NotFound: FC<NotFoundProps> = ({ type }) => {
  const image = require(`../../assets/common/${type}-not-found.svg`);
  const title =
    type === "order"
      ? "No orders are placed yet!"
      : type === "table"
      ? "Table is currently closed!"
      : "Invalid Payment";
  const description =
    type === "order"
      ? "After placing your order please scan QR code placed on the table to pay for your items"
      : type === "table"
      ? "Check with your waiter to make sure the table is open & ready to receive orders"
      : "";
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "98svh",
        padding: 2,
      }}
    >
      <Box
        sx={{
          borderRadius: "8px",
          border: "1px solid",
          borderColor: "primary.main",
          height: "100%",
          width: "100%",
          padding: 2,
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ width: "100%", display: "flex",justifyContent:"center",mb:"20px" }}>
          <Box
            sx={{
              backgroundColor: "white",
              width: "150px",
              height: "150px",
              borderRadius: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              filter: "drop-shadow(0px 3px 6px #00000029)",
              overflow: "hidden",
            }}
          >
            <Logo
              color="primary"
              sx={{
                cursor: "pointer",
                width: "100%",
              }}
            />
          </Box>
        </Box>
        <Box
          component="img"
          alt="not found"
          src={image}
          sx={{
            width: 80,
            marginX: "auto",
            display: "block",
            marginBottom: 5,
          }}
        />
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="h4"
            sx={{ textAlign: "center", marginBottom: 2, color: "primary.main" }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              marginBottom: 2,
              fontSize: 14,
              color: "text.secondary",
            }}
          >
            {description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;
