import { Box, BoxProps, SxProps } from "@mui/material";
import { FC } from "react";
interface LogoProps {
  color: "primary" | "light" | "dark";
  sx?: SxProps;
  boxProps?: BoxProps;
  goDefault?: boolean;
  onLoad?: () => void;
}

const CapitalMotionLogo: FC<LogoProps> = ({
  sx,
  color,
  boxProps,
  goDefault,
  onLoad,
}) => {
  return (
    <Box
      {...boxProps}
      component="img"
      alt="logo"
      src={
        "https://qrsolutionbucket130835-production.s3.us-east-2.amazonaws.com/public/logo.webp"
      }
      sx={sx}
      onLoad={onLoad}
    />
  );
};

export default CapitalMotionLogo;
