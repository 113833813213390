import { Table } from "../../models/API";

const SET_TABLE = "table/SET_TABLE";

export interface ITableState {
  table: Table | null;
}

const DEFAULT_STATE: ITableState = { table: null };

export default function tableReducer(state = DEFAULT_STATE, action: any = {}) {
  switch (action.type) {
    case SET_TABLE:
      return { ...state, table: action.table };

    default:
      return state;
  }
}

export const setTable = (table: Table | null) => ({
  table,
  type: SET_TABLE,
});
