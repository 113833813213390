import "./App.css";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LandingPage from "./pages/LandingPage/LandingPage";
import PdfView from "./components/PdfView/PdfView";
import Invoice from "./pages/Invoice/Invoice";
import SplitBill from "./pages/splitBill/SplitBill";
import ScannerPage from "./pages/ScannerPage";
import MenusPage from "./pages/MenusPage";
import RedirectHome from "./components/RedirectHome";
import { StoreState } from "./store/ducks";
import { useDispatch, useSelector } from "react-redux";
import { generateClient } from "aws-amplify/api";
import { onUpdateOrder } from "./graphql/subscriptions";
import {
  setOrder,
  setPaymentDue,
  setSelectedMenuItems,
} from "./store/ducks/order";
import PaymentResult from "./pages/PaymentResult";
import NotFound from "./components/NotFound";
import Terms from "./pages/Terms/Terms";
// test
const client = generateClient();
function App() {
  const { i18n } = useTranslation();
  const { order, selectedMenuItems } = useSelector(
    (state: StoreState) => state.order
  );
  const { table } = useSelector((state: StoreState) => state.table);
  const dispatch = useDispatch();

  useEffect(() => {
    const selectedLanguage = localStorage.getItem("language");

    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
      if (selectedLanguage === "Arabic") {
        document.documentElement.setAttribute("dir", "rtl");
      } else {
        document.documentElement.setAttribute("dir", "ltr");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subscription = client.graphql({
      query: onUpdateOrder,
    });

    if ("subscribe" in subscription) {
      const orderSubscription = subscription.subscribe({
        next: (event) => {
          const updatedOrder = event?.data?.onUpdateOrder;
          if (order?.id === updatedOrder?.id) {
            dispatch(setOrder(updatedOrder));

            // Check if there are selected menu items
            if (selectedMenuItems && selectedMenuItems.length > 0) {
              //update selected menu items
              const newSelectedItems = [...selectedMenuItems];
              selectedMenuItems?.forEach((selected, i) => {
                const menuItem = updatedOrder.MenuItems.find(
                  (mI) => selected.DetailLink === mI.DetailLink
                );
                if (!menuItem) {
                  newSelectedItems.splice(i, 1);
                  return;
                }
                if (menuItem?.paidQTY! >= menuItem?.SalesCount!) {
                  // console.log("Found paid item", menuItem);

                  newSelectedItems.splice(i, 1);
                  return;
                }

                newSelectedItems[i] = menuItem;
              });
              const price = newSelectedItems.reduce(
                (acc, item) => parseFloat((acc + item.totalAmount!).toFixed(2)),
                0
              );
              dispatch(setSelectedMenuItems(newSelectedItems));
              dispatch(setPaymentDue(price));
            }
          }
        },
        error: (error: any) => {
          console.error("Subscription error:", error);
        },
      });

      return () => {
        if ("unsubscribe" in orderSubscription) {
          orderSubscription.unsubscribe();
        }
      };
    }
  }, [order, dispatch, table, selectedMenuItems]);

  return (
    <Routes>
      <Route path="/" element={<ScannerPage />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/:id/payment-status" element={<PaymentResult />} />
      <Route path={"/:id"} element={<LandingPage />} />
      <Route path={"/:id/menu"} element={<MenusPage />} />
      <Route path="/:id/menu/pdfView" element={<PdfView />} />
      <Route
        path="/:id/payment"
        element={
          <RedirectHome>
            <Invoice />
          </RedirectHome>
        }
      ></Route>
      <Route
        path="/:id/splitBill"
        element={
          <RedirectHome>
            <SplitBill />
          </RedirectHome>
        }
      ></Route>
      <Route
        path="/noorder"
        element={
          <RedirectHome>
            <NotFound type={"order"} />
          </RedirectHome>
        }
      ></Route>
    </Routes>
  );
}

export default App;
