import styled from "@emotion/styled";
import { Grid, Switch, Typography, createTheme } from "@mui/material";
import { TranslationsEnum } from "../../constants/TranslationsEnum";
import { TiGift } from "react-icons/ti";
import lightThemeOptions from "../../theme/lightThemeOptions";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const lightTheme = createTheme(lightThemeOptions);
interface LoyaltySwitchProps {
  handleChange: (value: boolean) => void;
  switchValue: boolean;
}
export default function LoyaltySwitch({
  handleChange,
  switchValue,
}: LoyaltySwitchProps) {
  const { t, i18n } = useTranslation();
  const IOSSwitch = styled(Switch)(({ theme }) => ({
    width: 32, // Adjust the width
    height: 20, // Adjust the height
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transition: "all 0.3s ease-in-out",
      transform: "translateX(0px)", // Adjust the translation
      "&.Mui-checked": {
        transform: "translateX(11px)", // Adjust the translation
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "4px solid #fff", // Adjust the border thickness
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: "grey",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 16, // Adjust the width
      height: 16, // Adjust the height
    },
    "& .MuiSwitch-track": {
      borderRadius: 10, // Adjust the border radius
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: "all 0.3s ease-in-out",
    },
  }));
  const handleUsePoints = (e: any) => {
    handleChange(e.target.checked);
  };
  return (
    <Grid container direction="row" alignItems="center" rowSpacing={2}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          gap: 1,
          px: 1,
          mt: 1,
        }}
      >
        <TiGift size={"1.2rem"} style={classes.giftIcon} />
        <Typography sx={classes.redeemPointsText}>
          {t(TranslationsEnum.useLoyalty)}
        </Typography>
        <IOSSwitch
          disableRipple={true}
          checked={switchValue}
          onChange={handleUsePoints}
        />
        {/* <Switch
          disableRipple={true}
          sx={classes.switch}
          checked={switchValue}
          onChange={handleUsePoints}
        /> */}
      </Grid>
    </Grid>
  );
}
const classes = {
  giftIcon: {
    color: lightTheme.palette.primary.main,
  },
  redeemPointsText: {
    color: "black",
    fontSize: "14px",
  },
  switch: {
    "& .MuiSwitch-switchBase": {
      // transition: "all 0.3s ease-in-out",
      // transform: "translateX(0px)", // Adjust the translation
      "&.Mui-checked": {
        // transform: "translateX(11px)", // Adjust the translation
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: "grey",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#E5E5E7",
      opacity: 1,
      // transition: "all 0.3s ease-in-out",
    },
  },
};
