import { LamdaFnLinks } from "../constants/enums";
import { LoyaltyOperationIds } from "../constants/operationIds";
import axios, { AxiosError } from "axios";

export async function handleCreateTransactionToken(
  phoneNumber: string
): Promise<any> {
  try {
    const response = await axios.post(LamdaFnLinks.LoyaltyOperations, {
      operationId: LoyaltyOperationIds.generateToken,
      phone_number: phoneNumber,
    });

    return response.data;
  } catch (error: any) {
    console.error("Error creating transaction token:", error.message || error);
    console.log({ errorDetails: error.response?.data || error });
    throw error;
  }
}

export async function handleGetUserData(token: string | null, posId: string) {
  try {
    const response = await axios.post(LamdaFnLinks.LoyaltyOperations, {
      operationId: LoyaltyOperationIds.getUserDetails,
      token,
      venue: posId,
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
