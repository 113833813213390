export enum MoneyTypes {
  PERCENTAGE = "percentage",
  FIXED = "fixed",
}
export enum LamdaFnLinks {
  TableManagement = "https://xvon7mvcoplfwzgrpehrhufdoq0dmqta.lambda-url.us-east-2.on.aws/",
  SimphonyOperations = "https://uxhykzgzeqma4mew7bvmbbpfnq0iaoqt.lambda-url.us-east-2.on.aws/",
  PaymentOperations = "https://qh2g3lodfgrd227miwjwbvps3q0zpiho.lambda-url.us-east-2.on.aws/",
  LoyaltyOperations = "https://vxwdte5swxua4ineics3ia2vm40mvtgr.lambda-url.us-east-2.on.aws/",
}

export enum TableStatus {
  OCCUPIED = "occupied",
  AVAILABLE = "available",
  RESERVED = "reserved",
  UNAVAILABLE = "unavailable",
}

export enum PaymentChannels {
  APPLE = "applePay",
  SAMSUNG = "samsungPay",
  CARD = "cardPay",
}

export const userProfile = {
  _id: "67054df02d4d39a5992ad9fe",
  country_code: "+971",
  mobile_number: "547319437",
  __v: 0,
  badge: [],
  badgeCount: 0,
  badgeSender: null,
  bar_code: null,
  bio: "Can you imagine life without technology?",
  cash_accept: true,
  city_of_residence: "Dubai",
  country_of_residence: null,
  createdAt: "2024-10-08T15:21:20.441Z",
  customer_id: null,
  customer_tier: null,
  deactivate: false,
  delete_reason: null,
  deleted_at: null,
  device_token:
    "dH2XYzOeXEeBuLeQU69v-R:APA91bFNbASVsVEsOCQUQ8MMDoRGWRe_FJPp8cwMT0C8GGVcBayRCQtkO5kC3gzLySXFOhs4TAeB_AMPd62vbSewY4MfdcREXrbDGoOVluOqHR2q5uoaHJ8rDfyi789mKV1qyeD-4pZc",
  device_type: "Ios",
  display_name: "Pradeep",
  dob: "1981-03-01",
  email: "pradeep@capitalmotion.com",
  email_verify: true,
  fileId: null,
  first_name: "Pradeep",
  gallery_image: [
    "public/user/1729196888239.jpeg",
    "public/user/1729197054993.jpeg",
    "public/user/1729197083587.jpeg",
  ],
  gender: "Male",
  image: "public/user/1728401100874.jpeg",
  invalid_otp_count: 0,
  invite_table: true,
  is_block: false,
  is_location: true,
  is_verify: false,
  lastBadgeUpdate: null,
  lastTierUpdate: "2024-10-17T20:36:20.336Z",
  last_name: "Kumar",
  last_seen: null,
  latitude: 24.449964789925232,
  live_in_UAE: false,
  location: {
    type: "Point",
    coordinates: [54.60113241472846, 24.449964789925232],
  },
  longitude: 54.60113241472846,
  nationality: "India",
  new_buddies: true,
  otp: null,
  otp_verify: true,
  qr_code: null,
  referal: null,
  reference_by: null,
  refresh_token: false,
  remember_token: null,
  reset_password_token: null,
  seen_online: true,
  soSocialBlock: false,
  socket_id: "s1rLLWx1VUe_sE9bAAPQ",
  tier: [
    {
      _id: "6691580c40b97584b6aef66f",
      name: "Gold",
      color: "#ffd700",
      transactionFrom: 5000,
      transactionTo: 19999,
      percentage: 10,
      default: false,
      createdAt: "2024-07-12T16:21:32.752Z",
      updatedAt: "2024-10-07T07:24:15.203Z",
      __v: 0,
    },
  ],
  tierName: "Gold",
  tierRange: "5000-19999",
  timezone: "Asia/Dubai",
  transToken: "lj3P0Y2Irbqqvp2fVAjcGKwgH7YDPI4J",
  updateProfileStatus: true,
  updatedAt: "2024-10-18T09:11:20.737Z",
  wallet: {
    amount: 3777.9752644444447,
  },
  soSocialConversion: 0.75,
  percentageArray: 10,
};
