import React from "react";
import { Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  label: {
    marginTop: "10px",
    zIndex:9999,
    cursor:'pointer'
  },
  poweredByAnyware: {
    zIndex:9999,
    color: "#FFFFFF",
    fontFamily: "Inter-Regular, Helvetica",
    fontSize: 10,
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: "22px",
    top: 0,
    left: 0,
  },
  textWrapper: {
    marginRight: 4, // Adjust spacing as needed
    fontSize: 14,
  },
  span: {
    fontWeight: 600, // Adjust font weight as needed
    textDecoration: "underline",
    fontSize: 14,
  },
});

const PoweredBy: React.FC<any> = ({color}:any) => {
  const classes = useStyles();
  function openInNewTab(url:any) {
    var win = window.open(url, '_blank');
   
  }
  return (
    <Box className={classes.label} onClick={()=>openInNewTab("https://www.anyware.software")} >
      <Typography className={classes.poweredByAnyware} >
        <span className={classes.textWrapper} style={{color:color}}>Powered by </span>
        <span className={classes.span} style={{color:color}}>Anyware Software</span>
      </Typography>
    </Box>
  );
};

export default PoweredBy;
