import { MenuItem, Order } from "../../models/API";

//actions
const SET_ORDER = "order/SET_ORDER";
const SET_TOTAL = "order/SET_TOTAL";
const SET_INITIAL = "order/SET_INITIAL";
const SET_TIP = "order/SET_TIP";
const SET_PHONE_NUMBER = "order/SET_PHONE_NUMBER";
const SET_SERVICE_FEE = "order/SET_SERVICE_FEE";
const SET_PAYMENT = "order/SET_PAYMENT";
const SET_USED_CASH = "order/SET_USED_CASH";
const SET_PAYMENT_DUE = "order/SET_PAYMENT_DUE";
const SET_PAYMENT_TYPE = "order/SET_PAYMENT_TYPE";
const SET_SELECTED_MENU_ITEMS = "order/SET_SELECTED_MENU_ITEMS";
const SET_NO_OF_PEOPLE = "order/SET_NO_OF_PEOPLE";
const SET_PENDING_PAYMENTS = "order/SET_PENDING_PAYMENTS";

export interface IOrderState {
  order: Order | null;
  total: number;
  tip: number;
  phoneNumber: string;
  serviceFee: number;
  initial: number;

  paymentType: "full" | "partial";
  usedCash: number;
  currentDue: number;

  selectedMenuItems?: MenuItem[];
  noOfPeopleOnTable: number;
  noOfPeoplePayingFor: number;
  pendingPayment: number | null;
}
//default state
const DEFAULT_STATE: IOrderState = {
  order: null,
  total: 0, //total amount + service fee
  tip: 0,
  phoneNumber: "",
  serviceFee: 0,
  initial: 0,
  paymentType: "full",
  usedCash: 0,
  currentDue: 0,
  selectedMenuItems: undefined,
  noOfPeopleOnTable: 0,
  noOfPeoplePayingFor: 0,
  pendingPayment: null,
};

//reducer
export default function orderReducer(state = DEFAULT_STATE, action: any = {}) {
  switch (action.type) {
    case SET_ORDER:
      return { ...state, order: action.order };
    case SET_TOTAL:
      return { ...state, total: action.total };
    case SET_INITIAL:
      return { ...state, initial: action.initial };
    case SET_TIP:
      return { ...state, tip: action.tip };
    case SET_PHONE_NUMBER:
      return { ...state, phoneNumber: action.phoneNumber };
    case SET_SERVICE_FEE:
      return { ...state, serviceFee: action.serviceFee };
    case SET_USED_CASH:
      return { ...state, usedCash: action.usedCash };
    case SET_PAYMENT:
      return {
        ...state,
        total: action.total,
        serviceFee: action.serviceFee,
      };
    case SET_PAYMENT_DUE:
      return { ...state, currentDue: action.currentDue };
    case SET_PAYMENT_TYPE:
      return { ...state, paymentType: action.paymentType };
    case SET_SELECTED_MENU_ITEMS:
      return { ...state, selectedMenuItems: action.selectedMenuItems };
    case SET_PENDING_PAYMENTS:
      return { ...state, pendingPayment: action.pendingPayment };
    case SET_NO_OF_PEOPLE:
      return {
        ...state,
        noOfPeopleOnTable: action.noOfPeopleOnTable,
        noOfPeoplePayingFor: action.noOfPeoplePayingFor,
      };
    default:
      return state;
  }
}

//action creators
export const setOrder = (order: Order | null) => ({
  order,
  type: SET_ORDER,
});
export const setTotal = (total: number) => ({
  total,
  type: SET_TOTAL,
});
export const setInitial = (initial: number) => ({
  initial,
  type: SET_INITIAL,
});
export const setTip = (tip: number) => ({
  tip,
  type: SET_TIP,
});
export const setPhoneNumber = (phoneNumber: string) => ({
  phoneNumber,
  type: SET_PHONE_NUMBER,
});
export const setServiceFee = (serviceFee: number) => ({
  serviceFee,
  type: SET_SERVICE_FEE,
});
export const setUsedCash = (usedCash: number) => ({
  usedCash: usedCash,
  type: SET_USED_CASH,
});
export const setPayment = (payment: {
  total: number;
  serviceFee: number;
  // currentDue: number;
}) => ({
  total: payment.total,
  serviceFee: payment.serviceFee,
  // currentDue: payment.currentDue,

  type: SET_PAYMENT,
});

export const setPaymentType = (paymentType: "full" | "partial") => ({
  paymentType,
  type: SET_PAYMENT_TYPE,
});

export const setPaymentDue = (currentDue: number) => ({
  currentDue,
  type: SET_PAYMENT_DUE,
}); //currentDue

export const setSelectedMenuItems = (selectedMenuItems?: MenuItem[]) => ({
  selectedMenuItems,
  type: SET_SELECTED_MENU_ITEMS,
});
export const setPendingPayment = (pendingPayment: number) => ({
  pendingPayment,
  type: SET_PENDING_PAYMENTS,
});
export const setNumberOfPeople = (
  noOfPeopleOnTable: number,
  noOfPeoplePayingFor: number
) => ({
  noOfPeopleOnTable,
  noOfPeoplePayingFor,
  type: SET_NO_OF_PEOPLE,
});
