import "./SamsungPay.css";
import { Button } from "@mui/material";
import { SiSamsungpay } from "react-icons/si";
import { useSelector } from "react-redux";

const SamsungPayButton = () => {
  /* Payment Process */
  const currency = "AED";
  const method = "";
  const merchantIdentifier = "";

  const { order, paymentType, currentDue, selectedMenuItems } = useSelector(
    (state) => state.order
  );
  const table = useSelector((state) => state.table.table);
  const concept = useSelector((state) => state.app.conceptOptions);

  var request = null;
  var paymentDetails = null;

  async function onSamsungPayButtonTapped() {}

  return (
    <>
      {window.ApplePaySession && window.ApplePaySession.canMakePayments() && (
        <Button
          //   startIcon={loading && <CircularProgress size={20} />}
          sx={classes.finalPayment}
          onClick={onSamsungPayButtonTapped}
          //   disabled={loading}
        >
          <>
            Pay With
            <SiSamsungpay fontSize={36} style={{ marginLeft: "5px" }} />
          </>
        </Button>
      )}
    </>
  );
};

const classes = {
  finalPayment: {
    backgroundColor: "black",
    textTransform: "unset",
    fontSize: "15px",
    fontWeight: "bold",
    borderRadius: "32px",
    mb: "5px",
    py: "10px",
    "&:hover": {
      backgroundColor: "black",
    },
    "&:disabled": {
      backgroundColor: "gray",
      color: "#3a3a3a",
    },
  },
};

export default SamsungPayButton;
