import axios, { AxiosError } from "axios";
import {
  SimphonyOperationIds,
  TableManagementOperationIds,
} from "../constants/operationIds";
import { LamdaFnLinks } from "../constants/enums";
import { Order } from "../models/API";

export async function getOrderBySimphonyId({
  conceptId,
  simphonyId,
}: {
  simphonyId: string;
  conceptId: string;
}): Promise<Order | null> {
  try {
    const response = await axios.post(LamdaFnLinks.SimphonyOperations, {
      operation: SimphonyOperationIds.GET_ORDER_BY_TABLE,
      tableID: simphonyId,
      conceptID: conceptId,
    });

    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export interface Item {
  DetailLink: string;
  qty: number;
}
export interface PaymentType {
  type: "full" | "partial";
  amount: number;
  tips: number;
  serviceCharge: number;
  items?: Item[];
  userEmail?: string;
  paymentMethod?: string;
}
export interface PayOrderReturnType {
  success: boolean;
  message: string;
}

export async function payOrder({
  conceptId,
  tableSimphonyId,
  CheckNum,
  CheckSeq,
  payment,
}: {
  tableSimphonyId: string;
  conceptId: string;
  CheckNum: string;
  CheckSeq: string;
  payment: PaymentType;
}): Promise<PayOrderReturnType> {
  console.log(
    "payOrder",
    tableSimphonyId,
    conceptId,
    CheckNum,
    CheckSeq,
    payment
  );

  try {
    const response = await axios.post(LamdaFnLinks.SimphonyOperations, {
      operation: SimphonyOperationIds.PAY_ORDER,
      tableID: tableSimphonyId,
      conceptID: conceptId,
      CheckNum,
      CheckSeq,
      payment,
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    if ((error as AxiosError).response?.data) {
      return (error as AxiosError).response?.data as PayOrderReturnType;
    } else {
      return {
        success: false,
        message: "Something went wrong. Please try again.",
      };
    }
  }
}
export async function submitOrderRating(
  rating: number,
  review: string,
  id: string,
  orderID: string
) {
  try {
    const response = await axios.post(LamdaFnLinks.TableManagement, {
      operationId: TableManagementOperationIds.UPDATE_ORDER_RATING,
      rating,
      review,
      id,
      orderID,
    });
    console.log(response);
    const data = response.data;
    return data;
  } catch (error) {
    throw error;
  }
}
