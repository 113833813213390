import { LamdaFnLinks } from "../constants/enums";
import { LoyaltyOperationIds } from "../constants/operationIds";
import axios from "axios";

export async function handleSendOTP(token: string | null) {
  try {
    const response = await axios.post(LamdaFnLinks.LoyaltyOperations, {
      operationId: LoyaltyOperationIds.sendOtp,
      token,
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function handleVerifyOTP(token: string | null, otp: string) {
  try {
    const response = await axios.post(LamdaFnLinks.LoyaltyOperations, {
      operationId: LoyaltyOperationIds.verifyOtp,
      token,
      otp,
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.log("error verifying OTP" + error);
    throw error;
  }
}
