import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Slide,
  TextField,
  Typography,
  createTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslationsEnum } from "../../constants/TranslationsEnum";
import lightThemeOptions from "../../theme/lightThemeOptions";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../../store/ducks/app";
import MuiPhoneNumber from "mui-phone-number";
import { StoreState } from "../../store/ducks";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { handleSendOTP, handleVerifyOTP } from "../../services/otp";
import { getCurrentTip } from "../tipsComponent/TipsComponent";
import { calcWithServiceFees } from "../../pages/Invoice/InvoiceTable";
import {
  detectDevice,
  displayPrices,
  formatWithCommas,
  getEquivalentSosocial,
} from "../../helpers/utils";
import SoSocialLogo from "../../pages/LandingPage/SoSocialLogo";
import playStore from "../../assets/common/googlePlay.webp";
import appStore from "../../assets/common/appStore.webp";
import {
  soSocialAppStore,
  soSocialGooglePlay,
} from "../../constants/constants";
import OTPInput from "react-otp-input";
import {
  handleCreateTransactionToken,
  handleGetUserData,
} from "../../services/token";
import RedemptionModal from "../RedemptionModal/RedemptionModal";
import { wrap } from "module";
import { userProfile } from "../../constants/enums";
const lightTheme = createTheme(lightThemeOptions);

type Props = {
  open: boolean;
  onClose: () => void;
  earn: boolean;
  setUsedCash: (cash: number) => void;
  onAuth: () => void;
};

function AuthModal({ open, onClose, setUsedCash, earn, onAuth }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = useSelector((state: StoreState) => state.app.token);
  const [otpModalOpen, setOTPModalOpen] = useState<boolean>(false);
  const [timeLeftForResending, setTimeLeftForResending] = useState<number>(
    Number(localStorage.getItem("timeleftforresending"))
  );
  const [error, setError] = useState<boolean>(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [phoneNumber, setLocalPhoneNumber] = useState<string>("");
  const [phoneCode, setPhoneCode] = useState("+971");
  const [phoneCodeDisplay, setPhoneCodeDisplay] = useState("+971");
  const user = useSelector((state: StoreState) => state.app.user);
  const wallet_cash = Number(user?.wallet.amount);
  const { currentDue, order, tip } = useSelector(
    (state: StoreState) => state.order
  );
  const conceptOptions = useSelector(
    (state: StoreState) => state.app.conceptOptions
  );
  const maxBill = currentDue > wallet_cash ? wallet_cash : currentDue;
  const timeToWait = 30;
  useEffect(() => {
    if (!timeLeftForResending) return;
    const intervalId = setInterval(() => {
      setTimeLeftForResending(timeLeftForResending - 1);
      localStorage.setItem(
        "timeleftforresending",
        String(timeLeftForResending)
      );
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeftForResending]);

  const handleClose = () => {
    onClose();
  };

  const handleTypographyClick = () => {
    const flagButton = document.querySelector(
      ".MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium"
    );
    if (flagButton) {
      //@ts-ignore
      flagButton.click();
    }
  };

  const handleSuccessfulOTP = async () => {
    setOTPModalOpen(false);
    setUnregistered(false);
    if (earn) {
      onClose();
    }
  };

  const resetTimeLeftForResending = () => {
    setTimeLeftForResending(timeToWait);
    localStorage.setItem("timeleftforresending", `${timeToWait}`);
  };

  const [unregistered, setUnregistered] = useState(false);

  const handleOTP = async () => {
    setLoading(true);
    try {
      if (timeLeftForResending) {
        toast.error(
          `You have to wait ${timeLeftForResending} before re-authenticating`
        );
        return;
      }
      // if (!timeLeftForResending) {
      // localStorage.setItem("timeleftforresending", "timeToWait");
      // setTimeLeftForResending(timeToWait);
      let newToken = token;
      // const phoneCodeWithoutPlus = phoneCode;
      // const phoneNumberPrefix = phoneNumber.slice(
      //   0,
      //   phoneCodeWithoutPlus.length
      // );
      // const phoneNumberWithoutZero = phoneNumber.startsWith("0")
      //   ? phoneNumber.slice(1, phoneNumber.length)
      //   : phoneNumber;
      // console.log({ phoneCodeWithoutPlus, phoneNumberPrefix });
      let newPhone = `${phoneCode}${phoneNumber}`;

      if (newToken === undefined || newToken === null) {
        const response = await handleCreateTransactionToken(newPhone);
        localStorage.setItem("timeleftforresending", `${timeToWait}`);
        setTimeLeftForResending(timeToWait);
        // console.log({ response });
        if (response.success === false) {
          if (response.message === "User not found.") {
            // toast.error("unregistered user");
            setUnregistered(true);
          }
        } else {
          dispatch(setToken(response.data.transToken));
          newToken = response.data.transToken;
        }
      }
      if (newToken) {
        const response_1 = await handleSendOTP(newToken);
        if (response_1.result === "Failure") {
          toast.error("Error Sending OTP, please try again.");
          dispatch(setToken(null));
        }
        setUnregistered(false);
      }
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        if (error?.response?.data?.body) {
          if (error?.response?.data?.body?.message === "User not found.") {
            // setUnregistered(true);
          } else {
            toast.error(t("OTP Error, please try again."));
          }
        }
      }
      setTimeLeftForResending(0);
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneChange = (e: any) => {
    let value = "";
    value = e.target.value;
    if (value.startsWith("+")) {
      setError(true);
      setPhoneErrorMessage("You can only enter numbers.");
    } else {
      setError(false);
    }
    setLocalPhoneNumber(value);
  };

  const handlePhoneCodeChange = (e: any) => {
    setPhoneCode(e);
    setPhoneCodeDisplay(e);
  };

  const handleClick = () => {
    if (phoneNumber.length < 8) {
      setError(true);
    } else {
      handleOTP();
      setError(false);
    }
  };

  const setCash = (cash: number) => {
    setRedemptionModal(false);
    setUsedCash(cash);
    handleClose();
  };

  const [customInput, setCustomInput] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handlePaste = (e: any) => {
    let myPastedValue = e.clipboardData.getData("text");
    setLocalPhoneNumber(myPastedValue);
  };

  useEffect(() => {
    setLocalPhoneNumber("");
  }, []);

  const handleDownloadClick = () => {
    if (detectDevice(navigator.userAgent) === "iOS") {
      window.open(soSocialAppStore, "_blank", "noopener,noreferrer");
    } else {
      window.open(soSocialGooglePlay, "_blank", "noopener,noreferrer");
    }
  };

  const [otp, setOtp] = useState("");
  const [otpLoading, setOtpLoading] = useState<boolean>(false);
  const phone = useSelector((state: StoreState) => state.order.phoneNumber);

  const handleResend = async () => {
    try {
      setOtpLoading(true);
      const response = await handleSendOTP(phone);
      if (response.result === "Failure") {
        toast.error(t(TranslationsEnum.otpResendError));
      }
      resetTimeLeftForResending();
    } catch (error) {
      toast.error(t(TranslationsEnum.otpResendError));
    } finally {
      setOtpLoading(false);
    }
  };

  const handleOtpChange = (otp: string) => {
    setOtp(otp);
  };

  const [otpError, setOTPError] = useState<boolean>(false);

  const verifyOTP = async (token: string | null, otp: string) => {
    setOtpLoading(true);
    try {
      const response = await handleVerifyOTP(token, otp);
      if (response.result === "Failure") {
        setOTPError(true);
        toast.error("Error validating your otp.");
      } else if (response.data.transToken) {
        dispatch(setToken(response.data.transToken));
        const response_1 = await handleGetUserData(
          response.data.transToken,
          conceptOptions?.pos_id!
        );
        if (response_1.message === "unauthorized") {
          toast.error("Error retrieving your data.");
        } else {
          dispatch(setUser(response_1.data));
        }
        handleSuccessfulOTP();
        setOtp("");
        setOTPError(false);
        onAuth();
      }
    } catch (error) {
      toast.error("error verifying OTP");
      console.log("error verifying OTP" + error);
    }
    setOtpLoading(false);
  };

  useEffect(() => {
    // if (otp === "1234") {
    //   dispatch(setUser(userProfile));
    //   handleSuccessfulOTP();
    //   setOtp("");
    //   setOTPError(false);
    //   setOtpLoading(false);
    //   onAuth();
    //   return;
    // }
    if (otp.length === 4) {
      verifyOTP(token, otp);
    }
  }, [otp]);
  const [redemptionModal, setRedemptionModal] = useState(false);
  const loyaltyMood = useSelector((state: StoreState) => state.app.loyaltyMood);
  return (
    <>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 0,
          alignItems: "flex-end",
          backgroundColor: "unset",
        }}
        open={open}
        onClick={(e) => handleClose()}
      >
        <Slide in={open} direction="up">
          <Box sx={classes.containerBox} onClick={(e) => e.stopPropagation()}>
            <RedemptionModal
              open={redemptionModal}
              setCash={setCash}
              onClose={() => setRedemptionModal(false)}
            />

            <Grid container sx={classes.containerGrid}>
              <Grid
                item
                sx={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                xs={12}
              >
                {!user && (
                  <>
                    <Box sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "start",
                        }}
                      >
                        <Typography sx={classes.walletTitle}>
                          {" "}
                          {t(TranslationsEnum.newToSosocial)}
                        </Typography>
                        <IoIosCloseCircleOutline
                          color="red"
                          onClick={handleClose}
                          size={"24px"}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <Button
                        onClick={handleDownloadClick}
                        variant={"contained"}
                        sx={classes.earnWithSoSocial}
                      >
                        Create an Account
                      </Button>
                    </Box>
                    <Divider
                      orientation="horizontal"
                      sx={{
                        backgroundColor: "black",
                        width: "100%",
                        color: "black",
                        borderColor: "black",
                      }}
                    />
                  </>
                )}
                {!user && (
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{}}>
                      <Typography sx={classes.walletTitle}>
                        {" "}
                        Existing SoSocial Account
                      </Typography>
                      <Typography sx={classes.walletSubtitle}>
                        {t(TranslationsEnum.authVerify)}
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  {!user && (
                    <Box sx={classes.authContainer}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // borderColor: error ? "red" : "rgba(0,0,0,0.5)",
                          // borderWidth: "1px",
                          // borderStyle: "solid",
                          mt: "10px",
                          borderRadius: "20px",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            borderColor: error ? "red" : "rgba(0,0,0,0.5)",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderRadius: "5px",
                            width: "38%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <MuiPhoneNumber
                            variant="outlined"
                            onChange={handlePhoneCodeChange}
                            value={phoneCode}
                            placeholder={t(
                              TranslationsEnum.otpinputPlaceholder
                            )}
                            sx={{
                              ...classes.textField,
                              width: "20%",
                              borderColor: "transparent",
                            }}
                            defaultCountry="ae"
                            preferredCountries={["ae", "eg"]}
                            disableAreaCodes={true}
                            countryCodeEditable={false}
                            disabled={true}
                            onPaste={handlePaste}
                          />
                          <Button
                            sx={{ color: "#606060" }}
                            onClick={handleTypographyClick}
                          >
                            <Typography>{phoneCode}</Typography>
                          </Button>
                        </Box>
                        <Box
                          sx={{
                            borderColor: error ? "red" : "rgba(0,0,0,0.5)",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderRadius: "5px",
                          }}
                        >
                          <TextField
                            variant="outlined"
                            sx={classes.textField}
                            onChange={handlePhoneChange}
                            value={phoneNumber}
                            inputProps={{ autoComplete: "tel" }}
                            type="tel"
                            autoComplete="tel"
                            inputMode="tel"
                            disabled={Boolean(user)}
                          />
                        </Box>
                      </Box>
                      {error && (
                        <Typography color={"red"}>
                          {phoneErrorMessage}
                        </Typography>
                      )}
                      <Button
                        onClick={handleClick}
                        variant="contained"
                        sx={classes.authenticateButton}
                        startIcon={loading && <CircularProgress size={20} />}
                        disabled={loading || Boolean(user) || error}
                      >
                        Authenticate
                      </Button>
                    </Box>
                  )}

                  {unregistered ? (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          fontFamily={"Poppins"}
                          fontWeight={"medium"}
                          color={"#CE1127"}
                        >
                          You’re not registered in
                        </Typography>
                        <SoSocialLogo sx={{ width: "20%" }} background={true} />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                          mt: "10px",
                        }}
                      >
                        <Typography
                          fontFamily={"Poppins"}
                          fontWeight={"medium"}
                        >
                          Download the app now!
                        </Typography>
                        <Box
                          component={"img"}
                          src={
                            detectDevice(navigator.userAgent) === "iOS"
                              ? appStore
                              : playStore
                          }
                          sx={{ width: "40%" }}
                          onClick={handleDownloadClick}
                        />
                      </Box>
                    </Box>
                  ) : (
                    !earn &&
                    !redemptionModal && (
                      <Box
                        sx={{
                          border: "1px solid #C4C4C4",
                          borderRadius: "32px",
                          padding: "14px",
                          width: "102%",
                        }}
                      >
                        {user ? (
                          <>
                            <Box sx={{ mb: "16px", mt: "12px" }}>
                              <Typography fontSize={14}>
                                <Typography
                                  fontSize={12}
                                  component={"span"}
                                  fontWeight={"medium"}
                                >
                                  (Maximum usable on this bill:{" "}
                                  {getEquivalentSosocial(
                                    maxBill,
                                    user.soSocialConversion
                                  ).toFixed(2)}
                                  ) Social Cash
                                </Typography>{" "}
                              </Typography>{" "}
                              <Typography fontSize={14}>
                                Bill Amount: AED{" "}
                                {(
                                  currentDue +
                                  calcWithServiceFees({
                                    total: currentDue,
                                    serviceFee: conceptOptions?.serviceFee!,
                                  }).serviceFee +
                                  getCurrentTip({
                                    tip,
                                    total: order?.Due!,
                                    currentDue,
                                  })
                                ).toFixed(2)}{" "}
                              </Typography>{" "}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "10px",
                              }}
                            >
                              {loyaltyMood === "earn" && (
                                <Button
                                  onClick={() => handleClose()}
                                  variant="contained"
                                  sx={{
                                    ...classes.useAmountButton,
                                    "@media (max-width: 390px)": {
                                      fontSize: "11px", // Example adjustment for small screens
                                      padding: "12px 8px", // Adjust padding for smaller screens
                                    },
                                    "@media (max-width: 360px)": {
                                      fontSize: "10px", // Example adjustment for small screens
                                      padding: "8px 4px", // Adjust padding for smaller screens
                                    },
                                  }}
                                >
                                  Earn Social Cash
                                </Button>
                              )}
                              {loyaltyMood === "redeem" && (
                                <Button
                                  variant="contained"
                                  sx={{
                                    ...classes.customAmountButton,
                                    "@media (max-width: 390px)": {
                                      fontSize: "11px", // Example adjustment for small screens
                                      padding: "12px 8px", // Adjust padding for smaller screens
                                    },
                                    "@media (max-width: 360px)": {
                                      fontSize: "10px", // Example adjustment for small screens
                                      padding: "8px 4px", // Adjust padding for smaller screens
                                    },
                                  }}
                                  onClick={() => setRedemptionModal(true)}
                                  disabled={Boolean(Number(wallet_cash) < 1)}
                                >
                                  Use Social Cash
                                </Button>
                              )}
                            </Box>
                          </>
                        ) : (
                          <Box sx={classes.OTPcontainerBox}>
                            {otpLoading ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <CircularProgress color="primary" />
                              </Box>
                            ) : (
                              <>
                                <Grid container sx={classes.containerGrid}>
                                  <Grid item xs={12}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography sx={classes.modalTitle}>
                                        {t(TranslationsEnum.otpModalTitle)}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <OTPInput
                                    value={otp}
                                    onChange={handleOtpChange}
                                    numInputs={4}
                                    renderSeparator={
                                      <Box width={"1rem"}> </Box>
                                    }
                                    renderInput={(props: any) => (
                                      <input
                                        {...props}
                                        disabled={Boolean(!token)}
                                      />
                                    )}
                                    inputStyle={classes.inputStyle}
                                    inputType="number"
                                  />
                                  {otpError && (
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        color: "red",
                                        weight: "200",
                                      }}
                                    >
                                      {t(TranslationsEnum.otpVerifyError)}
                                    </Typography>
                                  )}
                                </Grid>
                                <Box
                                  style={{
                                    marginTop: "10px",
                                    textAlign: "center",
                                  }}
                                >
                                  {timeLeftForResending ? (
                                    <Typography
                                      sx={{
                                        color: "black",
                                        fontSize: 14,
                                        fontWeight: "200",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      {t(TranslationsEnum.otpResendTime, {
                                        timeleftforresending:
                                          timeLeftForResending,
                                      })}
                                    </Typography>
                                  ) : (
                                    token && (
                                      <>
                                        <span
                                          style={{
                                            color: "black",
                                            fontSize: 12,
                                            fontWeight: "medium",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          {t(TranslationsEnum.otpResendText)}
                                        </span>
                                        <Button
                                          variant="text"
                                          onClick={() => {
                                            handleResend();
                                          }}
                                          disabled={Boolean(!token)}
                                          style={classes.OTPResendLink}
                                        >
                                          {t(TranslationsEnum.otpResendLink)}
                                        </Button>
                                      </>
                                    )
                                  )}
                                </Box>
                              </>
                            )}
                          </Box>
                        )}
                      </Box>
                    )
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Slide>
      </Backdrop>
    </>
  );
}
const classes = {
  containerBox: {
    boxSizing: "border-box",
    position: "relative",
    width: "100%",
    background: "#FFFFFF",
    padding: "35px",
    borderRadius: "50px 50px 0px 0px",
    boxShadow: "0px 0px 1px 0px",
    pb: "70px",
  },
  OTPcontainerBox: {
    boxSizing: "border-box",
    position: "relative",
    width: "100%",
    background: "#FFFFFF",
    padding: {
      xs: "10px",
      sm: "1rem",
      md: "1.5rem",
    },
    // borderRadius: "50px 50px 0px 0px",
  },
  containerGrid: {
    height: "100%",
    gap: "1rem",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
  },
  walletTitle: {
    fontWeight: "bold",
    fontSize: "15px",
    color: lightTheme.palette.secondary.main,
  },
  walletSubtitle: {
    fontWeight: "medium",
    fontSize: "11.3px",
    color: lightTheme.palette.secondary.main,
    width: "100%",
  },
  earnWithSoSocial: {
    // backgroundColor: "black",
    background:
      "linear-gradient(135deg, rgba(207,58,113,1) 0%, rgba(252,216,40,1) 50%, rgba(159,199,50,1) 100%)",
    textTransform: "unset",
    fontSize: "14px",
    fontWeight: "bold",
    borderRadius: "32px",
    mb: "5px",
    py: "10px",
    width: "100%",
    color: "black",
    "&:hover": {
      backgroundColor: "black",
    },
    "&:disabled": {
      backgroundColor: "gray",
      color: "#3a3a3a",
    },
  },
  authContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  authenticateButton: {
    backgroundColor: "black",
    width: "80%",
    textTransform: "unset",
    fontSize: "14px",
    fontWeight: "bold",
    borderRadius: "32px",
    my: "18px",
    py: "10px",
    "&:hover": {
      backgroundColor: "black",
    },
    "&:disabled": {
      backgroundColor: "gray",
      color: "#3a3a3a",
    },
  },
  useAmountButton: {
    backgroundColor: "black",
    textTransform: "unset",
    fontSize: "12px",
    fontWeight: "medium",
    borderRadius: "32px",
    py: "13px",
    width: "90%",
    "&:hover": {
      backgroundColor: "black",
    },
  },
  customAmountButton: {
    backgroundColor: "transparent",
    textTransform: "unset",
    fontSize: "12px",
    fontWeight: "medium",
    borderRadius: "32px",
    color: lightTheme.palette.secondary.main,
    py: "10px",
    width: "90%",
    boxShadow: "none",
    border: "1px solid #C4C4C4",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  textField: {
    "& svg": { height: "1em" },
    "& .MuiOutlinedInput-root": {
      "& .MuiInputBase-input": {
        padding: "10px",
        px: "19px",
        color: lightTheme.palette.secondary.main,
        fontSize: "15px",
        fontWeight: "bold",
      },
      "& fieldset": {
        borderRadius: "32px",
        borderColor: "transparent !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
    },
  },
  inputStyle: {
    background: "white",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.10)",
    borderRadius: 10,
    border: "1px solid " + lightTheme.palette.primary.main,
    color: lightTheme.palette.secondary.main,
    fontSize: "24px",
    padding: "10px 12px",
    fontWeight: "bold",
    // "&:focusVisible": {
    //   backgroundColor: "gray",
    //   outline: "yellow auto 1px",
    // },
  },
  modalTitle: {
    color: lightTheme.palette.secondary.main,
    fontWeight: "bold",
    fontSize: "10px",
  },
  OTPResendLink: {
    color: "black",
    fontSize: "12px",
    fontWeight: "bold",
    cursor: "pointer",
  },
};
export default AuthModal;
