import { Box, BoxProps, Typography } from "@mui/material";
import React from "react";

interface MenusListItemProps extends BoxProps {
  title: string;
  icon?: React.ReactNode;
}

const MenusListItem = ({ title, icon, ...otherProps }: MenusListItemProps) => {
  return (
    <Box
      sx={{
        bgcolor: "white",
        borderRadius: "30px",
        border: (theme) => `1px solid ${theme.palette.primary.dark}`,
        px: 4,
        py: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        userSelect: "none",
        cursor: "pointer",
        transition: "0.3s",

        "&:hover": {
          bgcolor: "primary.light",
        },
      }}
      {...otherProps}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          width: "70%",
          marginLeft: "18%",
          justifyContent: "start",
        }}
      >
        {icon && icon}
        <Typography
          variant="h6"
          sx={{
            color: "secondary.main",
            textTransform: "capitalize",
            fontWeight: "500",
            fontSize: "16px",
          }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default MenusListItem;
