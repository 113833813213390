export enum ImagesPath {
  // relative paths to the assets folder
  HOME_SLIDER = "assets/tiki/home-slider",
  IVOICE_SLIDER = "assets/tiki/invoice-slider",
}

const home = Array.from({ length: 7 }, (_, i) => `${i + 1}.webp`);
export const HomeSliderImages = [...home];

const invoice = Array.from({ length: 5 }, (_, i) => `${i + 1}.jpg`);
export const InvoiceSliderImages = [...invoice];
