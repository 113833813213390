import { ThemeOptions } from "@mui/material/styles";
import { Colors } from "../constants/colors";

const lightThemeOptions: ThemeOptions = {
  typography: {
    fontFamily: `"Poppins","Roboto", "Helvetica", "Arial", sans-serif`,
  },
  direction: "ltr",
  palette: {
    primary: {
      light: Colors.PRIMARY_LIGHT,
      main: Colors.PRIMARY,
      dark: Colors.PRIMARY_DARK,
      contrastText: "#fff",
    },
    secondary: {
      light: "#a4a4a4",
      main: "#606060",
      dark: "#FFFFFF",
      contrastText: "#fff",
    },
    background: {
      paper: "#f6f2ee",
      default: "#F9F9F9",
    },
  },
  shape: {
    borderRadius: 0,
  },
};

export default lightThemeOptions;
