import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, createTheme } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import Languages from "../pages/Languages/Languages";
import { useTranslation } from "react-i18next";
import lightThemeOptions from "../theme/lightThemeOptions";

const LanguageSwitcher: React.FC = () => {
  let lightTheme = createTheme(lightThemeOptions);

  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [currentTarget, setCurrentTarget] = useState(false);

  return (
    <Button
      variant="contained"
      color="secondary"
      style={{
        color: lightTheme.palette.primary.dark,
        borderRadius: "20px",
        
        // width: "100%",
      }}
      onClick={(event: any) => {
        setOpen(!open);
        setCurrentTarget(event.currentTarget);
      }}
    >
      <LanguageIcon /> {i18n.language.substring(0, 2)}
      {open && (
        <div>
          <Languages type="icon" currentTarget={currentTarget} />
        </div>
      )}
    </Button>
  );
};

export default LanguageSwitcher;
