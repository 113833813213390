import { Box, CircularProgress, Grid } from "@mui/material";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/ducks";

const SplashScreen = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.defaultMuted = true;
      // videoRef.current.play();
    }
  }, []);

  return (
    <Grid
      item
      container
      sm={12}
      justifyContent="center"
      alignItems="center"
      flexDirection={"column"}
      sx={{
        height: "100vh",
        background: "white",
        position: "absolute",
        zIndex: 9999,
        gap: "20px",
      }}
    >
      <video
        ref={videoRef}
        autoPlay
        muted
        width={"100%"}
        height={"100%"}
        playsInline
        loop
      >
        <source src={require("../../assets/v1.mp4")} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Grid>
  );
};

export default SplashScreen;
